.service-onlineVideo {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 1rem;
  right: 0;
  transform-origin: 0 0;
  width: 0.68rem;
  height: 0.68rem;
  user-select: none;
  outline: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 200;
  .service-online-icon {
    width: inherit; //.3rem;
    height: inherit; //.3rem;
    pointer-events: none;
    transform: translateY(-0.07rem);
  }
  .service-online-text {
    display: none;
    background: #c01925;
    border-radius: 1rem;
    color: #ffffff;
    font-size: 0.12rem;
    width: 0.48rem;
    text-align: center;
  }
}
.video-wrap {
  position: fixed;
  width: 100%;
  top: 0.8rem;
  display: none;
  z-index: 200;
  .video-header {
    position: relative;
    left: 5%;
    width: 90%;
    height: 0.3rem;
    background-color: #000000;
    .icon-close-video {
      position: absolute;
      display: block;
      width: 0.2rem;
      height: 0.2rem;
      right: 0;
      top: 0.05rem;
      background: url('../../../images/wechat/clear.png') no-repeat;
      background-size: 100%;
      z-index: 100;
    }
    // .icon-close {
    //   position: absolute;
    //   display: block;
    //   width: 0.3rem;
    //   height: 0.3rem;
    //   right: 0;
    //   top: .02rem;
    //   background: url('../../../images/wechat/clear.png') no-repeat;
    //   background-size: 100%;
    //   z-index: 100;
    // }
  }
  #dplayer {
    left: 5%;
    width: 90%;
    height: 300px;
  }
}
