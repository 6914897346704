.lead-banner {
  position: fixed;
  top: 10%;
  left: 5%;
  z-index: 200;
  width: 90%;
  height: 80%;
  background: #ffffff;
  border-radius: 0.2rem;
  overflow-y: auto;
  .knowledge-container {
    padding-left: 0.15rem;
    padding-right: 0.15rem;
    line-height: 0.22rem;
    .note-title {
      color: #000000;
      font-size: 0.17rem;
      padding: 0.1rem 0;
      font-weight: bold;
    }
    .note-center {
      text-align: center;
      font-size: 0.19rem;
    }
    .note-blod {
      font-weight: bold;
    }
  }
}
.slideJump {
  position: fixed;
  z-index: 202;
  width: 0.31rem;
  height: 0.31rem;
  right: 0.1rem;
  top: 0.35rem;
}
.leadMsk {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 109;
  width: 100%;
  height: 100%;
  background-color: #333333;
  opacity: 0.3;
}
.serviceAge {
  padding: 0.2rem 0.15rem;
  overflow-y: auto;
}
.serviceIn {
  margin-top: 0.1rem;
  .note-title {
    color: #000000;
    font-size: 0.17rem;
    padding: 0.1rem 0;
    font-weight: bold;
  }
  .note-center {
    text-align: center;
    font-size: 0.19rem;
  }
  .note-blod {
    font-weight: bold;
  }
  .note-wrap {
    word-wrap: break-word; /* 允许长单词换行 */
    word-break: break-all; /* 强制长单词换行 */
  }
}
