@use 'sass:math';
@charset "UTF-8";
@import '../../../variables.scss';

$desk-page-padding-vertical: 10px;

.page {
  display: block;
  position: relative;
}

.scroll-touch {
  -webkit-overflow-scrolling: touch;
}

.env-mobile .page {
  position: absolute;
  height: 100vh;
  width: 100vw;

  .page-header,
  .page-footer {
    position: absolute;
  }

  .page-body {
    position: absolute;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    overflow-y: scroll; //auto; update by wendy.gu 2018 Mar.20
  }
}

.page-header {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 44px;
  // 设置成49, step-in-row-content会被设置成50
  z-index: $page-header-z-index;
}

.page-body {
  display: block;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-top: 44px;
  padding-bottom: $height-bot-bar;
}

.page-footer {
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  bottom: -1px;
  height: 48px;
  line-height: 48px;
  z-index: $page-footer-z-index;
}

// for desktop and pad and tablet
html.env-desktop,
html.env-ipad {
  // height: 100%;
  // body {
  //     height: 100%;
  // }
  .page-header {
    height: $height-top-bar;
  }

  .page-body {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0.44rem;
    padding-bottom: $height-bot-bar;
    min-height: 100vh;

    .page-body-container {
      display: block;
      position: relative;

      .page-body-location {
        display: block;
        position: relative;
        padding: 5px 0;
        color: $color-font-light2;
        font-size: $font-size-m;
        font-weight: 500;
        @include container;

        .path {
          display: block;
          position: relative;
          float: left;
          //noinspection CssInvalidFunction
          margin-right: math.div($btn-padding-l, 2);
          //noinspection CssInvalidFunction
          margin-left: math.div($btn-padding-l, 2);
          cursor: pointer;

          &:first-child {
            margin-left: 0;
          }

          &:not(:first-child):before {
            content: '/';
            display: block;
            position: absolute;
            //noinspection CssInvalidFunction
            left: 0 - math.div($btn-padding-l, 2) - 2px;
            cursor: default;
          }
        }
      }
    }
  }

  // page footer always in bottom

  .page-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
