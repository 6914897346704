@import '../../../variables.scss';
.desk-bottom {
  display: block;
  position: relative;
  padding-left: $html-padding-horizon;
  padding-right: $html-padding-horizon;
  background-color: $desk-page-footer-bg-color;
  height: $page-footer-height;
  line-height: $page-footer-height;
  .copyright {
    display: block;
    position: relative;
  }
}