@import 'variables.scss';
@import '~font-awesome/css/font-awesome.css';
// @import "~material-design-icons/iconfont/material-icons.css";
*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: $html-font-size;
  width: 100%;
  min-height: 100%;
  // 灰色滤镜
  //filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1) !important;
  //-webkit-filter: grayscale(100%) !important;
}

body {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: $body-margin;
  padding: $body-padding;
  font-size: $font-size;
  color: $color-font-main-title;
  background: $color-page-bg;
  font-family: $font-family;
  line-height: $line-height;
  background-color: $color-body-bg;
  overflow: hidden;
  overflow-y: auto;
  //add by wendy.gu 2017 Nov.20
  @include for-phone-only {
    -webkit-user-select: none;
    user-select: none;
  }
}

#root {
  display: block;
  position: relative;
}

input,
select {
  line-height: $line-height;
  height: $row-height;
  padding-top: $text-padding-vertical;
  padding-bottom: $text-padding-vertical;
  font-size: $font-size;
}

select {
  // -webkit-appearance: none;
  cursor: pointer;
}

input[disabled],
select[disabled] {
  opacity: 0.7;
  cursor: default;
  &.label-like {
    opacity: 1;
  }
}

input[type='text'],
input[type='password'],
input[type='number'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='url'],
input[type='checkbox'],
input[type='radio'],
input[type='date'],
select,
textarea {
  display: block;
  border: none;
  border-radius: 0;
  outline: none;
  margin: 0;
  color: $color-font;
  background: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
}

a {
  color: $color-font;
  line-height: $line-height;
  cursor: pointer;
}

a,
a:link,
a:visited,
a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}

a.form-btn,
a.form-btn-transparent {
  display: block;
  position: relative;
  text-align: center;
  height: $row-height;
  padding-top: $text-padding-vertical;
  padding-bottom: $text-padding-vertical;
  @include btn-big;
}

a.form-btn-transparent {
  font-size: $font-size-m;
}

a.form-btn {
  color: $color-reverse;
  font-size: $font-size-l;
  font-weight: 603;
  @include btn-form;
}

html.csms-lang-zh,
html.csms-lang-zh-cn,
html.csms-lang-zh-tw {
  a.form-btn,
  a.form-btn-transparent {
    &.gap {
      letter-spacing: 17px;
      padding-left: $btn-padding-l + 16px;
    }
  }
}

html.env-mobile {
  input[type='text'] {
    // remove the top inner shadow in ios
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

div {
  &.slide-left-enter,
  &.slide-left-enter .page-header,
  &.slide-left-enter .page-footer {
    left: 100%;
  }
  &.slide-left-enter .page-header,
  &.slide-left-enter .page-footer {
    right: -100%;
  }
  &.slide-left-enter.slide-left-enter-active,
  &.slide-left-enter.slide-left-enter-active .page-header,
  &.slide-left-enter.slide-left-enter-active .page-footer {
    left: 0;
    transition: left $page-slide-time linear;
  }
  &.slide-left-enter.slide-left-enter-active .page-header,
  &.slide-left-enter.slide-left-enter-active .page-footer {
    right: 0;
    transition: left $page-slide-time linear, right $page-slide-time linear;
  }
  &.slide-left-leave,
  &.slide-left-leave .page-header,
  &.slide-left-leave .page-footer {
    left: 0;
  }
  &.slide-left-leave .page-header,
  &.slide-left-leave .page-footer {
    right: 0;
  }
  &.slide-left-leave.slide-left-leave-active,
  &.slide-left-leave.slide-left-leave-active .page-header,
  &.slide-left-leave.slide-left-leave-active .page-footer {
    left: -100%;
    transition: left $page-slide-time linear;
  }
  &.slide-left-leave.slide-left-leave-active .page-header,
  &.slide-left-leave.slide-left-leave-active .page-footer {
    right: 100%;
    transition: left $page-slide-time linear, right $page-slide-time linear;
  }
  &.slide-right-enter,
  &.slide-right-enter .page-header,
  &.slide-right-enter .page-footer {
    left: -100%;
  }
  &.slide-right-enter .page-header,
  &.slide-right-enter .page-footer {
    right: 100%;
  }
  &.slide-right-enter.slide-right-enter-active,
  &.slide-right-enter.slide-right-enter-active .page-header,
  &.slide-right-enter.slide-right-enter-active .page-footer {
    left: 0;
    transition: left $page-slide-time linear;
  }
  &.slide-right-enter.slide-right-enter-active .page-header,
  &.slide-right-enter.slide-right-enter-active .page-footer {
    right: 0;
    transition: left $page-slide-time linear, right $page-slide-time linear;
  }
  &.slide-right-leave,
  &.slide-right-leave .page-header,
  &.slide-right-leave .page-footer {
    left: 0;
  }
  &.slide-right-leave .page-header,
  &.slide-right-leave .page-footer {
    right: 0;
  }
  &.slide-right-leave.slide-right-leave-active,
  &.slide-right-leave.slide-right-leave-active .page-header,
  &.slide-right-leave.slide-right-leave-active .page-footer {
    left: 100%;
    transition: left $page-slide-time linear;
  }
  &.slide-right-leave.slide-right-leave-active .page-header,
  &.slide-right-leave.slide-right-leave-active .page-footer {
    right: -100%;
    transition: left $page-slide-time linear, right $page-slide-time linear;
  }
  &[class*='slide-'] {
    position: absolute;
    width: 100vw; // .page-header {
    // 	position: absolute;
    // }
    .page-body {
      height: calc(100vh - #{$page-header-height} - #{$page-footer-height});
    } // .page-footer {
    // 	position: absolute;
    // }
    &.me-quote-list {
      .page-body {
        height: calc(
          100vh - #{$page-header-height} * 2 - #{$page-footer-height}
        );
      }
    }
  }
}
.adm-swiper-slide {
  width: 100% !important;
  position: relative !important;
}

@import 'desk-index.scss';
