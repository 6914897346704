@import '../../../variables.scss';
$policy-section-margin-top: 30px;

.desk-policy {
  .policy-section {
    margin-top: $policy-section-margin-top;
    display: block;
    position: relative;
    @include desk-sec;
    @include container;

    .policy-section-header {
      padding: 0 $desk-sec-padding-horizon;
      line-height: $row-height;
      height: $row-height;

      .policy-section-header-title {
        display: block;
        position: relative;
        height: $row-height;
        line-height: $row-height;
        font-size: $font-size-l;
        font-weight: 600;
      }
    }

    .policy-section-body {
      display: block;
      position: relative;
      padding: 0 $desk-sec-padding-horizon $desk-sec-padding-vertical;
      @include container;

      .form-row {
        padding-left: $desk-sec-padding-horizon;
        padding-right: $desk-sec-padding-horizon;
        margin-bottom: 16px;
        &.new-row,
        &:first-child {
          padding-left: 0;
        }

        .form-label {
          font-size: $font-size-s2;
          line-height: $font-size-m;
          padding-top: 0;
          padding-bottom: 0;
          width: 100%;

          > span {
            border-bottom: 0;
            line-height: calc(
              #{$font-size-m} + #{$desk-text-padding-vertical} * 2
            );
            height: calc(#{$font-size-m} + #{$desk-text-padding-vertical} * 2);
            padding: 0 $desk-sec-padding-vertical;
          }
        }

        .form-input {
          line-height: $font-size-m;
          padding-top: 0;
          padding-bottom: 0;
          width: 100%;

          > span {
            line-height: calc(
              #{$font-size-m} + #{$desk-text-padding-vertical} * 2 - 2px
            );
            height: calc(#{$font-size-m} + #{$desk-text-padding-vertical} * 2);
            border: 1px solid $color-font-light45;
            border-radius: $border-radius * 2;
            padding: 0 $desk-sec-padding-vertical;
            background-color: $color-font-light6;
          }
          > div {
            line-height: calc(
              #{$font-size-m} + #{$desk-text-padding-vertical} * 2 - 2px
            );
            height: calc(#{$font-size-m} + #{$desk-text-padding-vertical} * 2);
            border: 1px solid $color-font-light45;
            border-radius: $border-radius * 2;
            padding: 0 $desk-sec-padding-vertical;
            background-color: $color-font-light6;
            &::before {
              border-bottom: none;
            }
            > input,
            select {
              margin-top: -4px;
            }
          }
        }
      }

      .form-table {
        display: table;
        border-top: 1px solid;
        border-right: 1px solid;
        padding-right: 0;
        margin-top: 10px;
        .form-table-header-group {
          display: table-header-group;
        }
        .form-table-row-group {
          display: table-row-group;
        }
        .form-table-row {
          display: table-row;
        }
        .form-table-cell {
          display: table-cell;
          border-left: 1px solid;
          text-align: center;
          border-bottom: 1px solid;
          height: 50px;
          background-color: $color-font-light6;
          line-height: 50px;
          > div {
            > input {
              text-align: center;
            }
          }
        }
      }
      .array-panel-section {
        border: 1px solid #006596;
        border-radius: 4px;
        margin-top: 10px;
        padding-bottom: 5px;
      }
    }
  }
  .button {
    cursor: pointer;
    background-color: $desk-color-std;
    color: $color-reverse;
    text-align: center;
    border-radius: 8px;
    &.addPanel {
    }
    &.addArrayPanel {
      height: 30px;
      line-height: 30px;
      width: 110px;
    }
    &#addArrayRow {
      background-color: $desk-color-std;
    }
    &.deletePanel {
      background-color: $color-border-dark2;
      display: block;
      width: 44px;
      height: 30px;
      line-height: 30px;
      margin-top: 10px;
    }
    &.deleteArrayPanel {
      background-color: $color-border-dark2;
      display: block;
      width: 30px;
      height: 25px;
      line-height: 25px;
      margin-top: 5px;
    }
    &#deleteArrayRow {
      background-color: $color-border-dark2;
      color: $color-reverse;
    }
  }
}
