.search-wrap.select {

    .searchClick{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .search-box-window {
        .open {
            & > ul {
                display: block;
            }
            & > li {
                &:first-child {
                    padding: 0.15rem;
                    padding: 0.15rem;
                    background-image: url("../../../images/wechat/arrow_down_active.png");
                    background-repeat: no-repeat;
                    background-position: 0 center;
                    background-size: 0.1rem auto;
                }
            }
        }
        .close {
            & > ul {
                display: none;
            }

            & > li {
                &:first-child {
                    padding: 0.15rem;
                    background-image: url("../../../images/mobile/arrow_down.png");
                    background-repeat: no-repeat;
                    background-position: 0 center;
                    background-size: 0.1rem auto;
                }
            }
        }
        .selectList {
            padding-bottom: 1rem;
            height: calc(100% - 0.75rem);
            overflow: scroll;
            li {
                padding-top: 0.15rem;
                padding-bottom: 0.15rem;
            }
            .lev1 {
                padding: 0 0.15rem;
                & > li {
                    border-bottom: 1px solid #dbdbdb;
                }
                .lev2 {
                    & > li {
                        border-bottom: 1px dashed #dbdbdb;
                        padding-left: 0.3rem;
                    }

                    &.open {
                        & > li {
                            &:first-child {
                                background-position: 0.15rem center;
                            }
                        }
                    }
                    &.close {
                        & > li {
                            &:first-child {
                                background-position: 0.15rem center;
                            }
                        }
                    }
                    .lev3 {
                        & > li {
                            border-bottom: 1px dashed #dbdbdb;
                            padding-left: 0.45rem;
                        }
                        &.open {
                            & > li {
                                &:first-child {
                                    background-position: 0.3rem center;
                                }
                            }
                        }
                        &.close {
                            & > li {
                                &:first-child {
                                    background-position: 0.3rem center;
                                }
                            }
                        }
                        .lev4 {
                            & > li {
                                border-bottom: 1px dashed #dbdbdb;
                                padding-left: 0.6rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
