@use 'sass:math';
@import '../../../variables.scss';

$sth-color-green: $color-std;
$sth-color-std: $sth-color-green;

$sth-input-height: 45px;
$sth-text-padding: 0.01rem 0.025rem;
$sth-step-in-row-height: 0.16rem;
$sth-article-height: 0.2rem;
$sth-radio-selected-color: $sth-color-std;
$sth-radio-icon-size: 0.2rem;
$sth-radio-icon-inner-size: 0.1rem;
$sth-toggle-bg-color: $color-font-light4;
$sth-toggle-checked-bg-color: $sth-color-std;
$sth-toggle-width: 0.55rem;
$sth-toggle-height: 0.3rem;

$sth-container-body-vertical-padding: 10px;
$sth-panel-header-height: $height-input;
$sth-panel-header-icon-color: $sth-color-std;
// $sth-panel-header-height-80: $sth-panel-header-height - 10px;

$sth-quote-condition-height: 0.45rem;
$sth-quote-condition-icon-color: $sth-color-std;
$sth-guard-points-height: 0.45rem;
$sth-guard-points-icon-color: $sth-color-std;

$sth-currency-color: $sth-color-std;

$sth-cell-label-width: 35%;

//
@mixin sth-panel() {
  display: block;
  position: relative;
  background-color: $color-cont-bg;
  padding: 0 $padding-page;
  margin-bottom: $margin-contaier;
  // border-top: $border-size-std;
  // border-bottom: $border-size-std;
  box-sizing: border-box;
  // box-shadow: $box-shadow-float-bottom;
  @include container;
}

div.sth {
  background: $color-font-light6;
  padding-bottom: 10px;

  input[type='checkbox'],
  input[type='date'],
  input[type='email'],
  input[type='number'],
  input[type='password'],
  input[type='radio'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='url'],
  select,
  textarea {
    color: $color-font-light2;
  }

  &.with-bottom-button {
    padding-bottom: $page-footer-height + 10px;
  }

  // 基本组件
  .sth-icon-labels {
    display: block;
    position: relative;
    @include container;

    .sth-icon-labels-icon {
      display: block;
      position: relative;
      float: left;
      padding-right: 5px;
      line-height: $line-height;

      img {
        height: 18px;
        width: 18px;
      }
    }

    .sth-icon-labels-label {
      display: block;
      position: relative;
      float: left;
    }
  }
  //职业
  .sth-job-category {
    width: 100%;
  }
  // 步进行
  .sth-step-in-row {
    display: block;
    position: relative;
    height: $sth-step-in-row-height;
    line-height: $sth-step-in-row-height;
    // padding-right: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {
      display: block;
      position: relative;
    }

    &:after {
      display: block;
      position: absolute;
      top: 0;
      right: 2px;
      content: '\f105';
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      height: $sth-step-in-row-height;
      line-height: $sth-step-in-row-height;
      font-size: $font-size-20;
      color: lighten($color-font-light3, 10%);
      transform: scale(1.3);
    }

    .sth-step-in-content {
      position: fixed;
      left: 100vw;
      top: 0;
      width: 100vw;
      min-height: 100vh;
      overflow-y: auto;
      height: 100%;
      background: $color-font-light6;
      z-index: $sth-step-in-content-z-index;

      .sth-container-body {
        padding-top: $row-height + 10px;
        padding-bottom: 10px;
      }

      &.narrow > .sth-container-body {
        padding-left: $html-padding-horizon;
        padding-right: $html-padding-horizon;

        .sth-button.bottom-button {
          margin-left: 0 - $html-padding-horizon;
          margin-right: 0 - $html-padding-horizon;
        }
      }

      &.with-bottom-button > .sth-container-body {
        padding-bottom: $page-footer-height + 10px;
      }

      > .top-bar {
        position: fixed;
        z-index: 1;
        width: 100vw;

        .title {
          font-size: $font-size-st;
        }
      }
    }

    &.sth-single-step-in-row {
      background: $color-reverse;
      padding: 0 $html-padding-horizon;
      height: $row-height;
      line-height: $row-height;
      margin-bottom: 10px;
      box-shadow: $box-shadow-float-bottom;

      &:after {
        height: $row-height;
        line-height: $row-height;
        right: $html-padding-horizon + 2px;
      }
    }
  }
  // 步进行集合
  .sth-step-in-rows {
    > .sth-step-in-row:not(:last-child) {
      border-bottom: 1px solid $color-font-light45;
    }
  }
  // 文章
  .sth-article {
    background: #fff;

    span {
      line-height: $sth-article-height;
      white-space: normal;
      display: block;
      padding: 0.05rem 0;

      > b {
        font-weight: 0;
        font-size: $font-size-price;
        color: #f00;
      }
    }

    span.title {
      font-weight: bold;
      border-bottom: 0.5px dashed $color-cont-border;
    }

    span.header {
      font-size: 0.16rem;
      font-weight: bold;
    }

    span.bold {
      font-weight: bold;
    }
  }
  // 吸底按钮
  .sth-button.bottom-button {
    position: fixed;
    bottom: 0;
    width: 100vw;
    padding-left: 0;
    padding-right: 0;
  }
  // 单选框
  .sth-radio {
    display: block;
    position: relative;
    @include container;
    height: $height-input;
    line-height: $height-input;

    > span {
      display: block;
      position: relative;
      float: left;
      cursor: pointer;
      width: 48%;
      @include container;

      &:not(:last-child) {
        margin-right: 5px;
      }

      .sth-radio-icon {
        display: block;
        position: relative;
        float: left;
        width: 25px;
        // 设置一个高度, 把label撑开
        height: 10px;

        &:before {
          content: '';
          display: block;
          position: absolute;
          //noinspection CssInvalidFunction
          top: math.div($height-input - $sth-radio-icon-size, 2);
          left: 0;
          width: $sth-radio-icon-size;
          height: $sth-radio-icon-size;
          border: 1px solid $sth-radio-selected-color;
          border-radius: 100%;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          //noinspection CssInvalidFunction
          top: math.div($height-input, 2);
          //noinspection CssInvalidFunction
          left: math.div($sth-radio-icon-size, 2);
          width: 0;
          height: 0;
          background: $sth-radio-selected-color;
          border-radius: 100%;
          transition: top 200ms linear, left 200ms linear, width 200ms linear,
            height 200ms linear;
        }
      }

      .sth-label {
        display: block;
        position: relative;
        float: left;
      }

      &.checked {
        .sth-radio-icon:after {
          //noinspection CssInvalidFunction
          top: math.div($height-input - $sth-radio-icon-size, 2) +
            math.div($sth-radio-icon-size - $sth-radio-icon-inner-size, 2);
          //noinspection CssInvalidFunction
          left: math.div($sth-radio-icon-size - $sth-radio-icon-inner-size, 2);
          width: $sth-radio-icon-inner-size;
          height: $sth-radio-icon-inner-size;
        }
      }
    }

    &.sth-radio-vertical {
      height: auto;

      > span {
        width: 100%;
      }
    }

    &.sth-radio-button {
      height: auto;
      display: flex;
      flex-wrap: wrap;

      > span {
        flex: 1 0 18%;
        border: $border-size-std;
        border-radius: $border-radius-std;
        margin-right: 5%;

        > span {
          width: 100%;
          text-align: center;
        }

        &.checked {
          background: $color-font-tab-ck;
          color: #fff;
        }
      }
    }
  }
  // 日期
  .sth-date {
    display: inline-block;
    position: relative;
    border: $border-size-menu;

    .sth-date-display,
    .sth-date-invisible {
      width: 100%;
      border: 1px solid $color-border;
      border-radius: $border-radius;
      height: $height-input;
      padding: $sth-text-padding;
    }

    .sth-date-picker {
      display: block;
      line-height: $height-input;
      color: $color-font-disble;
      padding: 0 0.025rem;
    }

    .sth-date-display-native {
      // 让事件穿透到下方组件, 即date
      pointer-events: none;
      cursor: pointer;
    }

    .sth-date-invisible {
      opacity: 0;
      color: transparent;
      background: transparent;
      border: none;
      box-shadow: none;
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }
  // 生日和年龄
  .sth-birth-age {
    display: block;
    position: relative;
    @include container;

    .sth-age {
      float: left;
      width: calc(35% - 5px);
      margin-right: 5px;
    }

    .sth-birth {
      float: left;
      width: 65%;
    }
  }
  // 下拉框
  .sth-dropdown {
    &.sth-dropdown-no-value {
      color: lighten($color-font-light2, 10%);
    }
  }

  .sth-simple-job-mask {
    border-radius: 2px;
    line-height: $height-input;
    padding: 0.01rem 0.07rem;
    width: 100%;
    color: #666666;
    position: absolute;
    pointer-events: none;

    span {
      background-color: #fff;
    }
  }

  .sth-dropdown,
  .sth-text {
    border: $border-size-menu;
    // border: 1px solid $color-border;
    border-radius: $border-radius;
    height: $height-input;
    padding: $sth-text-padding;
  }
  /**add by sanxiang.zeng  2018 Jan 29*/
  #sth-text {
    padding: initial;
  }

  // 标签
  .sth-label {
    height: $height-input;
    line-height: $height-input;
  }
  // 纵向标签集
  .sth-vertical-labels {
    display: block;
    position: relative;

    .sth-label {
      display: block;
      border-bottom: 1px solid $color-border;

      &:last-child {
        border-bottom: 0;
      }
    }
  }
  // 多行输入框
  .sth-textarea {
    border: $border-size-menu;
    // border: 1px solid $color-border;
    border-radius: $border-radius;
    height: $height-input * 3;
    padding: $sth-text-padding;
    width: 100%;
  }
  // toggle
  .sth-toggle {
    display: block;
    position: relative;
    height: $sth-toggle-height;
    width: $sth-toggle-width;
    background: $sth-toggle-bg-color;
    //noinspection CssInvalidFunction
    border-radius: math.div($sth-toggle-height, 2);
    cursor: pointer;

    .sth-toggle-false {
      float: right;
      width: 50%;
      text-align: center;
      color: $color-font-disble;
      line-height: $sth-toggle-height;
    }

    .sth-toggle-true {
      float: left;
      color: $color-btn-font;
      width: 50%;
      text-align: center;
      line-height: $sth-toggle-height;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: $sth-toggle-height - 0.01rem;
      height: $sth-toggle-height - 0.01rem;
      border-radius: 100%;
      left: 1px;
      top: 1px;
      background: $color-font-light7;
      transition: left 200ms linear;
    }

    &.sth-toggle-checked {
      background: $sth-toggle-checked-bg-color;

      &:after {
        left: $sth-toggle-width - ($sth-toggle-height - 0.01rem) - 0.005rem;
      }
    }

    &.sth-label-toggle {
      border-radius: $border-radius;
      background: $sth-toggle-checked-bg-color;

      > span {
        display: block;
        position: relative;
        float: left;
        width: 50%;
        text-align: center;
        color: $color-reverse;
      }

      &:after {
        border-radius: $border-radius;
        width: 50%;
      }

      &.sth-toggle-checked {
        &:after {
          left: calc(50% - 1px);
        }
      }
    }
  }

  .sth-spread {
    position: relative;
    @include container;

    .sth-spread-header {
      .sth-spread-header-cell {
        position: relative;
        float: left;
        font-weight: 500;
      }
    }

    .sth-spread-body-row {
      .sth-spread-body-cell {
        position: relative;
        float: left;
        color: $color-font-light3;
        font-size: $font-size-s2;
        min-height: 1px;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    .sth-spread-body-row,
    .sth-spread-header {
      position: relative;
      @include container;
      border-bottom: 1px solid darken($color-border, 10%);
    }
  }

  .sth-colunm {
    width: 100%;
    border: 0.005rem solid #000;
    border-spacing: 0;

    tr {
      border-bottom: 0.005rem solid #000;

      td {
        text-align: center;
        line-height: $height-single-txt;
        border-right: 0.005rem solid #000;
        border-bottom: 0.005rem solid #000;
      }
    }

    tbody {
      tr {
        &:last-child td {
          border-bottom: 0;
        }

        td {
          &:last-child {
            border-right: 0;
          }
        }
      }
    }

    thead {
      tr {
        td {
          font-weight: bold;

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }
  // 基本容器
  .sth-container-body {
    display: block;
    position: relative;
    padding-top: $sth-container-body-vertical-padding;
    padding-bottom: $sth-container-body-vertical-padding;
  }

  .sth-body-none {
    height: 0;
    padding: 0;

    .sth-step-in-row {
      height: 0;
    }
  }

  .sth-cell {
    display: block;
    position: relative;
    @include container;

    > * {
      display: block;
      position: relative;
      float: left;
    }

    > *:not(.sth-label) {
      width: 63%;

      &.sth-toggle {
        width: $sth-toggle-width;
        height: $sth-toggle-height;
        //noinspection CssInvalidFunction
        margin-top: math.div($height-input - $sth-toggle-height, 2);

        .sth-toggle-false {
          float: right;
          width: 50%;
          text-align: center;
          color: $color-font-disble;
          line-height: $sth-toggle-height;
        }

        .sth-toggle-true {
          float: left;
          color: $color-btn-font;
          width: 50%;
          text-align: center;
          line-height: $sth-toggle-height;
        }
      }
    }

    > .unit {
      width: 53%;
    }

    > .sth-cell-unit {
      width: 10%;
      float: right;
      text-align: center;
      line-height: $height-input;
    }

    > .sth-label {
      width: $sth-cell-label-width;
    }

    .sth-address {
      display: flex;

      .sth-dropdown:not(:last-child) {
        margin-right: 5px;
      }

      .sth-dropdown {
        width: calc((100% - 10px) / 3);
      }
    }
  }
  // 面板容器
  .sth-panel {
    @include sth-panel;

    .sth-panel-header {
      display: block;
      position: relative;
      font-size: $font-size-20;
      font-weight: 500;
      color: $color-font-light2;
      text-align: center;
      line-height: $height-input;
      height: $height-input;

      .sth-panel-header-icon {
        color: $sth-panel-header-icon-color;
        padding-right: 3px;
      }

      .right-label {
        float: right;
      }

      hr {
        position: absolute;
        display: block;
        margin: 0;
        border: 0;
        border-top: 2px solid $color-font-light45;
        width: 100%;
        bottom: 0;
        transition: opacity 400ms linear;
      }

      .collapsible-btn {
        display: none;
        transition: transform 400ms linear;
        color: $color-font-light3;
        line-height: $sth-panel-header-height;
        height: $sth-panel-header-height;
      }

      .clause-btn {
        display: none;
        margin-left: 30px;
        padding: 1px;
        border-radius: 5px;
        height: 30px;
        line-height: 30px;
        background: blue;
        color: #fff;
      }

      .sth-toggle {
        float: right;
        //noinspection CssInvalidFunction
        margin-top: math.div($sth-panel-header-height - $sth-toggle-height, 2);
      }

      > .sth-label:last-child {
        line-height: $sth-panel-header-height;
        height: $sth-panel-header-height;
        float: right;
      }

      &.left {
        text-align: left;
      }

      &.shift-panel {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        height: auto;

        .title {
          flex: 1 0 30%;
          text-align: center;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
          background: $color-catecory-tab-bg-bg;
          border: $border-size-std;
          color: $color-font-sub-title;
          font-size: $font-size-st;
          line-height: 0.3rem;
          margin: 1%;
          white-space: nowrap;

          &.active {
            background: $color-catecory-tab-btn-select;
            color: $color-font-prompt;
            border: $border-select-size-tab;
          }
        }
      }

      &.declaration-panel {
        font-size: $font-size-st;
        line-height: $height-multi-txt2;
        height: auto;
        min-height: $height-input;

        span {
          color: $color-sign-button;
        }

        .icon {
          display: block;
          height: $height-input;
          width: $icon-size-sp;
          float: left;
          margin-right: $margin-icon-txt2;
          background-repeat: no-repeat;
          background-position: center;
          background-size: $icon-size-sp;

          &.icon-select1 {
            background-image: url('../../../images/mobile/icon_select_1.png');
          }

          &.icon-select2 {
            background-image: url('../../../images/mobile/select_2.png');
          }
        }
      }

      &.resize-80 {
        font-size: $font-size-m;
        font-weight: normal;
        line-height: $height-input;
        height: $height-input;

        .collapsible-btn {
          line-height: $height-input;
          height: $height-input;
        }

        .sth-toggle {
          float: right;
          //noinspection CssInvalidFunction
          margin-top: math.div($height-input - $sth-toggle-height, 2);
        }

        > .sth-label:last-child {
          line-height: $height-input;
          height: $height-input;
          float: right;
        }

        .sth-customer-import,
        > .sth-ocr-import {
          float: right;
          color: $color-font-next;
          font-size: 0.14rem;
          // width: $width-import-btn;
          padding: 0 0.02rem;
          height: $height-import-btn;
          line-height: $height-import-btn;
          text-align: center;
          border: $border-size-menu;
          border-color: $color-std;
          border-radius: $border-radius-std;
          margin-left: 0.1rem;
          //noinspection CssInvalidFunction
          margin-top: math.div($height-input - $height-import-btn, 2);
          position: relative;

          .upload-input {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
          }

          span {
            height: 100%;
          }
        }

        .icon {
          float: right;
          width: $width-del-btn;
          height: $height-del-btn;
          background-repeat: no-repeat;
          background-position: center;
          background-size: $icon-size-sp;
          //noinspection CssInvalidFunction
          margin-top: math.div($height-input - $height-del-btn, 2);

          &.icon-close {
            background-image: url('../../../images/mobile/icon_close.png');
          }
        }
      }

      &.currency-header {
        > .sth-label:last-child {
          color: $sth-currency-color;
        }
      }

      &.collapsible {
        hr {
          opacity: 0;
        }

        .collapsible-btn {
          position: absolute;
          display: block;
          right: 0;
          top: 0;
          transform: rotate(180deg);
        }

        &.expanded {
          hr {
            opacity: 1;
          }

          .collapsible-btn {
            transform: rotate(0);
          }
        }
      }

      &.clause {
        .clause-btn {
          display: inline-block;
          vertical-align: center;
          padding: 0;
          border-radius: 5px;
          border: 1px solid $color-std;
          height: 30px;
          width: 0.4rem;
          line-height: 30px;
          background: $color-cont-bg;
          color: $color-std;
          font-size: $font-size-st;
          position: absolute;
          top: 7px;
          margin-left: 4%;

          .sth-single-step-in-row {
            height: 25px;
            line-height: 25px;
            color: $color-font-main-title;
          }
        }

        .sth-step-in-row:after {
          display: none;
        }

        .sth-container-body {
          text-align: left;
        }
      }
    }

    &.cells .sth-panel-body {
      > .sth-cell:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  .sth-array-panel {
    > .sth-panel.sth-array-panel-header {
      margin-bottom: 0;
    }

    > .sth-panel.sth-in-array-panel {
      box-shadow: none;
      padding: 0;
      margin-bottom: 0.1rem;

      &:not(:last-child) {
        border-bottom: 1px solid $color-border;
      }

      // &:not(:first-child) {
      //   margin-top: 5px;
      // }

      > .sth-panel-header {
        display: none;
      }
    }
  }

  // 业务组件
  // 产品信息头
  .sth-product-info-header {
    @include sth-panel;
    padding: $sth-product-info-header-padding $padding-page;
    border-top: 0;
    height: $sth-product-info-header-height;

    > div {
      display: block;
      position: relative;
      float: left;
    }

    .sth-product-info-header-name {
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      width: 63%;

      span {
        display: block;
        color: $color-font-time;
        font-size: $font-size-number;
      }
    }

    .sth-product-vendor-logo {
      position: absolute;
      right: $padding-page;
      width: $sth-product-info-header-logo-width;
      height: $sth-product-info-header-logo-height;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    .sth-product-info-liked {
      width: 100px;
      //noinspection CssInvalidFunction
      height: math.div($sth-product-info-header-height, 2);
      padding: 2px 10px;
      text-align: right;
      font-size: $font-size-s2;
      //noinspection CssInvalidFunction
      line-height: math.div($sth-product-info-header-height, 2);
      color: $color-font-light3;

      &.liked {
        color: $color-std;
      }

      span:first-child {
        margin-right: 5px;
      }
    }
  }
  // 投保条件
  .sth-quote-conditions {
    display: block;
    position: relative;

    .sth-icon-labels {
      // height: $sth-quote-condition-height;
      line-height: $sth-quote-condition-height;

      .sth-icon-labels-icon {
        color: $sth-quote-condition-icon-color;
        line-height: $sth-quote-condition-height;
      }

      .sth-icon-labels-label:nth-child(2) {
        color: $color-font-light2;

        &:after {
          content: ':';
          width: 10px;
          display: block;
          float: right;
        }
      }

      .sth-icon-labels-label:not(:nth-child(2)) {
        color: $color-font-light3;
      }
    }
  }
  // 保障内容
  .sth-guard-points {
    display: block;
    position: relative;
    @include container;

    .sth-icon-labels {
      height: $sth-guard-points-height;
      line-height: $sth-guard-points-height;
      float: left;
      //noinspection CssInvalidFunction
      min-width: percentage(math.div(1, 2));

      .sth-icon-labels-icon {
        color: $sth-guard-points-icon-color;
        line-height: $sth-quote-condition-height;
      }
    }
  }
  // 保险责任
  .sth-duty {
    display: block;
    position: relative;

    .sth-panel-header {
      font-size: $font-size;
      color: $color-font-light2;
      text-align: left;
      // height: calc(#{$font-size} + 10px);
      // line-height: calc(#{$font-size} + 10px);
      padding: 0 $padding-page;

      &.collapsible {
        hr {
          border-top-width: 1px;
        }
      }

      .collapsible-btn {
        // height: calc(#{$font-size} + 10px);
        // line-height: calc(#{$font-size} + 10px);
        &.fa-fw {
          font-size: 0.2rem;
        }
      }
    }

    .sth-container-body {
      .sth-label {
        font-size: $font-size-s2;
        color: $color-font-light3;
        line-height: $height-molti-txt;
      }
    }
  }
  // 保险责任集合
  .sth-duties {
    display: block;
    position: relative;

    .sth-duty:not(:last-child) {
      border-bottom: 1px solid $color-font-light45;
    }
  }

  // 建议书发送给
  .sth-advice-to {
    .sth-container-body {
      @include container;
      // padding-top: 0;
      // padding-bottom: 0;
      .sth-label {
        float: left;
        width: 30%;
      }

      .sth-text {
        float: left;
        width: 30%;
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid darken($color-border, 10%);
      }

      .sth-toggle {
        float: right;
        width: 100px;
        //noinspection CssInvalidFunction
        margin-top: math.div($height-input - $sth-toggle-height, 2);
      }
    }
  }

  // 附加险选择
  .sth-additional-coverages {
    display: block;
    position: relative;

    .sth-additional-coverage {
      border-bottom: 1px solid $color-border;
      padding: 0;
      box-shadow: none;

      .sth-panel-header {
        hr {
          border-width: 1px;
          border-color: $color-border;
        }
      }
    }
  }

  .sth-additional-coverage {
  }

  .sth-image {
    width: 100%;
  }

  //是否法定
  .sth-legalIndiId-toggle {
    float: right;
    margin-right: 0.1rem;

    .sth-legalIndiId-toggle-label {
      float: left;
      margin-right: $margin-contaier;
      color: $color-font-disble;
    }
  }

  //导入客户
  .sth-import-customer {
    position: fixed;
    left: -100vw;
    top: 0;
    width: 100vw;
    min-height: 100vh;
    overflow-y: auto;
    height: 100%;
    z-index: 1050;
    background: #fff;

    .page-body {
      padding-top: 0;
      position: relative;

      .customer-index-wrap {
        width: $height-index-btn;
        text-align: center;
        position: absolute;
        color: $color-font-main-title;
        right: $margin-dv-sp10;
        top: 20vh;
        height: 80vh;
        overflow-y: scroll;

        .customer-index {
          height: $height-index-btn;
          line-height: $height-index-btn;
          text-align: center;
          border-radius: 50%;
          margin-bottom: $margin-dv-sp10;
          font-weight: 600;
          font-size: $font-size-letter-navi;

          &.active {
            background: $color-btn-bg;
            color: $color-btn-font;
          }
        }
      }

      .customer-list-header {
        box-sizing: border-box;
        border-bottom: $border-size-std;
        height: $height-title-sp;
        line-height: $height-title-sp;
        background: $color-cont-bg;
        color: $color-font-sub-title;

        span {
          width: 33%;
          height: 100%;
          float: left;
          color: $color-font-sub-title;
          box-sizing: border-box;
          text-align: center;

          &.active {
            color: $color-btn-bg;
            border-bottom: $border-left-size-tab;
          }
        }
      }

      .customer-group-title {
        padding: 0 $padding-page;
        height: $height-top-status-bar;
        line-height: $height-top-status-bar;
        background: $color-list-tab-bg;
        text-align: left;
        color: $color-font-main-title;
      }

      .customer-group-content {
        padding: 0 $padding-page;
        background: $color-cont-bg;

        .customer-item {
          height: $height-list-item-sp;
          line-height: $height-list-item-sp;
          border-bottom: $border-size-std;
          text-align: left;
          color: $color-font-main-title;
        }

        .customer-item:last-child {
          border: none;
        }
      }
    }
  }

  .sth-ocr-import {
    $height-process-circle-container: 0.6rem;
    $height-process-circle: 0.3rem;
    $border-process-circle: 0.02rem solid $color-font-disble;

    $height-upload-container: 2rem;

    .page-body {
      padding-top: 0;
    }

    .panel {
      margin: $margin-contaier 0;
      border-bottom: $border-size-std;
      border-top: $border-size-std;
      background: $color-cont-bg;

      .font-color-red {
        color: $color-font-prompt;
      }

      .header {
        height: $height-title-sp;
        line-height: $height-title-sp;
        padding-left: $padding-page;
        border-bottom: $border-size-std;

        &.no-border {
          border: 0;
        }

        .icon {
          display: block;
          height: 100%;
          width: $icon-size-sp;
          float: left;
          margin-right: $margin-icon-txt2;
          background-repeat: no-repeat;
          background-position: center;
          background-size: $icon-size-sp;

          &.icon-person-infor {
            background-image: url('../../../images/mobile/icon_person_infor.png');
          }

          &.icon-identification {
            background-image: url('../../../images/mobile/icon_identification.png');
          }
        }
      }

      .content {
        padding: 0 $padding-page;

        ul {
          padding: 0 0 $padding-page;

          li {
            list-style: decimal;
            margin-left: $padding-page;
          }
        }

        &.padding-top {
          padding-top: $padding-page;
        }

        &.tips {
          font-size: $font-size-sub-title;
        }

        .tip {
          margin: $margin-search 0;
          font-size: $font-size-number;
          color: $color-font-sub-title;
        }

        .process {
          display: flex;

          div {
            flex: 1;
            height: $height-process-circle-container;
            text-align: center;
            color: $color-font-sub-title;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            overflow: hidden;

            &.active {
              color: $color-font-state;

              span {
                color: $color-font-state;
                border-color: $color-font-state;
              }

              &::before {
                background: $color-btn-bg;
              }

              &::after {
                background: $color-btn-bg;
              }
            }

            span {
              display: block;
              width: $height-process-circle;
              height: $height-process-circle;
              line-height: $height-process-circle - 0.05rem;
              border-radius: 50%;
              border: $border-process-circle;
              color: $color-font-disble;
            }

            p {
              text-align: center;
              margin: $margin-contaier 0 0;
            }

            &::before {
              content: '';
              display: block;
              height: 0.02rem;
              width: 50%;
              position: absolute;
              top: 25%;
              //noinspection CssInvalidFunction
              left: math.div(-$height-process-circle, 2);
              background: $color-font-disble;
            }

            &::after {
              content: '';
              display: block;
              height: 0.02rem;
              width: 50%;
              position: absolute;
              top: 25%;
              //noinspection CssInvalidFunction
              right: math.div(-$height-process-circle, 2);
              background: $color-font-disble;
            }

            &:first-child::before {
              display: none;
            }

            &:last-child::after {
              display: none;
            }
          }
        }

        .bottom {
          height: $height-title-sp;
          line-height: $height-title-sp;
          padding-right: $padding-page;
          // border-bottom: $border-size-std;
          color: $color-font-sub-title;

          span {
            float: right;
            color: $color-font-main-title;
          }

          .icon {
            display: block;
            height: 100%;
            width: $icon-size-sp;
            float: right;
            margin-right: $margin-icon-txt2;
            background-repeat: no-repeat;
            background-position: center;
            background-size: $icon-size-sp;

            &.icon-sample {
              background-image: url('../../../images/mobile/icon_video.png');
            }
          }
        }
      }

      .upload {
        padding: $padding-page;

        .upload-container {
          margin-bottom: $margin-contaier;
          position: relative;

          .delete {
            height: $icon-size-sp;
            width: $icon-size-sp;
            background: url('../../../images/mobile/icon_close.png') center
              no-repeat;
            background-size: $icon-size-sp;
            position: absolute;
            //noinspection CssInvalidFunction
            top: math.div(-$icon-size-sp, 2);
            //noinspection CssInvalidFunction
            right: math.div(-$icon-size-sp, 2);
            z-index: 49;
          }

          .img-container {
            height: $height-upload-container;
            line-height: $height-upload-container;
            text-align: center;
            border: 0.005rem dashed $color-cont-border;
            background: $color-photo-bg;
            color: $color-font-disble;
            position: relative;
            overflow: hidden;

            img {
              width: calc(100% + 2px);
              height: calc(100% + 2px);
              object-fit: contain;
              margin: -1px;
              position: absolute;
              left: 0;
              top: 0;
            }

            input {
              opacity: 0;
              height: 100%;
              width: 100%;
              position: absolute;
              left: 0;
              top: 0;
            }
          }

          p {
            margin: 0;
            text-align: center;
            color: $color-font-disble;
            padding: $padding-container 0;
          }
        }
      }

      .form {
        padding: 0 $padding-page;
        margin-bottom: $padding-page;

        .text-container {
          height: $height-list-item-sp;
          display: flex;
          margin-bottom: $margin-input;

          &:first-child {
            margin-top: $margin-input;
          }

          input {
            width: 100%;
          }

          select {
            width: 100%;
          }

          .label {
            line-height: $height-list-item-sp;
            flex: 3;
          }

          .input-container {
            flex: 7;
            height: $height-list-item-sp;
            border: $border-size-std;
            border-radius: $border-radius-std;
            padding-left: $padding-input;
          }

          .date-age-contaner {
            flex: 7;
            height: $height-list-item-sp;
            display: flex;
            @mixin dateAgePanel() {
              border: $border-size-std;
              border-radius: $border-radius-std;
              padding-left: $padding-input;
            }

            .age-container {
              @include dateAgePanel;
              flex: 2;
              margin-right: $margin-input;
            }

            .date-container {
              @include dateAgePanel;
              flex: 3;
            }
          }

          .gender-container {
            flex: 7;
            height: $height-list-item-sp;
            display: flex;

            div {
              width: 100%;
              flex: 1;

              label {
                display: block;
                height: $height-list-item-sp;
                width: $icon-size-sp;
                float: left;
                margin-right: $margin-icon-txt2;
                background-repeat: no-repeat;
                background-position: left;
                background-size: $icon-size-sp;
                background-image: url('../../../images/mobile/radio_1.png');

                &.active {
                  background-image: url('../../../images/mobile/radio_2.png');
                }
              }

              span {
                height: $height-list-item-sp;
                line-height: $height-list-item-sp;
              }
            }
          }
        }
      }
    }
  }
  //利益演示
  .sth-benefit-presentation {
    .rang-container {
      width: 100%;
      position: relative;
      height: 48px;
      white-space: nowrap;

      .handle,
      input {
        float: left;
      }

      .handle {
        width: 8%;
        text-align: center;

        .icon {
          display: block;
          height: $height-title-sp;
          width: $icon-size-sp;
          margin: 0 auto;
          background-repeat: no-repeat;
          background-position: center;
          background-size: $icon-size-sp;
        }

        .icon-plus {
          background-image: url('../../../images/mobile/icon_plus.png');
        }

        .icon-minus {
          background-image: url('../../../images/mobile/icon_minus.png');
        }
      }

      .tip {
        top: 15px;
        left: 33px;
        height: 20px;
        width: 20px;
        position: absolute;
        background: #000;
        border-radius: 50%;
        color: #fff;
        background: $color-btn-bg;
        display: block;
        text-align: center;
        line-height: 20px;
      }
    }

    .selection {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      select {
        width: 60px;
        padding: 0;
        height: 26px;
        text-align: center;
        border: $border-size-std;
        border-radius: $border-radius-std;

        option {
        }
      }
    }

    .content {
      div {
        line-height: 2;
      }

      span {
        line-height: 2;
        float: right;
      }
    }

    input[type='range'] {
      -webkit-appearance: none;
      width: 80%;
    }

    input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 12px;
      width: 12px;
      margin-top: -5px;
      /*使滑块超出轨道部分的偏移量相等*/
      background: $color-btn-bg;
      border-radius: 50%;
      /*外观设置为圆形*/
      border: solid 0.1em rgba(205, 224, 230, 0.5);
      /*设置边框*/
    }

    input[type='range']::-webkit-slider-runnable-track {
      height: 1px;
      border-radius: 10px;
      background: #666;
      /*将轨道设为圆角的*/
    }

    input[type='range']:focus {
      outline: none;
    }
  }
  //告知
  .sth-declares {
    //是否选项
    .sth-declara-toggle-remark {
      background: $color-cont-bg;
      //noinspection CssInvalidFunction
      padding: math.div($padding-page, 2) $padding-page;
      margin-bottom: $margin-contaier;
      font-size: $font-size-st;
      color: $color-font-main-title;

      .sth-delara-warp {
        position: relative;
        height: 100%;
        width: 100%;
      }

      .sth-declara-label {
        width: 77.333%;
      }

      .sth-declara-toggle {
        position: absolute;
        top: calc(50% - 0.29rem / 2);
        right: $padding-page;
      }

      .sth-declara-area {
        width: 100%;
        margin-top: $margin-contaier;
      }
    }
    //含有多项输入
    .sth-declara-input-wrap {
      width: 100%;

      .sth-declara-input-item {
        width: 100%;
        // height: $height-input;
        margin-top: $margin-input;
        clear: both;

        .sth-declara-input-name {
          width: 35%;
          float: left;
          // height: 100%;
          // line-height: $height-input;
        }

        .sth-declara-input-name2 {
          width: 88%;
          float: left;
          // height: 100%;
          // line-height: $height-input;
        }

        .sth-declara-input-content {
          width: 65%;
          border: $border-size-menu;
          border-radius: $border-radius;
          height: $height-input;
          padding: $sth-text-padding;
        }

        .sth-declara-radio-point {
          width: 25px;
          height: 25px;
          float: right;
        }
      }
    }
    //单选告知
    .sth-declara-radio {
      background: $color-cont-bg;
      //noinspection CssInvalidFunction
      padding: math.div($padding-page, 2) $padding-page;
      margin-bottom: $margin-contaier;

      .sth-radio-warp {
        display: flex;
        flex-flow: row wrap;

        .sth-declara-radio-warp {
          width: 100%;
          display: flex;
          flex-flow: row wrap;
          margin-top: 20px;

          .sth-declara-radio-point {
            width: 20px;
            height: 20px;
          }

          .sth-radio-remark-hide {
            display: none;
          }

          .sth-radio-remark-show {
            width: 100%;
            display: flex;
            margin: 10px;

            .sth-radio-remark-name {
              line-height: 30px;
              width: 25%;
            }

            .sth-radio-remark-input {
              border: solid 1px;
              width: 75%;
              height: 30px;
            }
          }
        }
      }
    }

    .sth-declara-parent {
      background: $color-cont-bg;
      padding: 0 $padding-page;
    }

    .children-of-parent {
      margin-bottom: 0;
    }
  }
  //告知显示 用户核对订单页
  .sth-declara-show {
    background: $color-cont-bg;
    //noinspection CssInvalidFunction
    padding: math.div($padding-page, 2) $padding-page;
    margin-bottom: $margin-contaier;
    font-size: $font-size-st;
    color: $color-font-main-title;

    .sth-declara-show-warp {
      position: relative;
      height: 100%;
      width: 100%;
    }

    .sth-declara-show-label {
      width: 77.333%;
      color: $color-font-sub-title;
    }

    .sth-declara-show-confirm {
      position: absolute;
      top: calc(50% - 0.09rem);
      right: $padding-page;
    }

    .sth-declara-show-remark {
      width: 90%;
      margin-top: $margin-contaier;
      word-wrap: break-word;
    }

    .sth-declare-special-item {
      width: 100%;
      display: table;
      margin-top: $margin-contaier;

      .sth-declare-special-label {
        width: 50%;
        display: table-cell;
        padding-right: $padding-search;
        // vertical-align: middle;
      }

      .sth-declare-special-remark {
        width: 50%;
        display: table-cell;
        // vertical-align: middle;
      }
    }
  }

  .sth-label-text {
    display: flex;

    .sth-label-name {
      width: 35%;
      line-height: 0.45rem;
      color: $color-font-sub-title;
    }

    .sth-label-value {
      line-height: 0.45rem;
      color: $color-font-main-title;
      width: 65%;
    }

    &.sth-one-line-label-text {
      position: relative;
      background-color: $color-cont-bg;
      padding: 0 $padding-page;
      margin-bottom: $margin-contaier;
      box-sizing: border-box;
    }
  }

  .sth-label-text-r {
    text-align: right;

    .sth-label-name {
      color: $color-font-sub-title;
      display: inline-block;
    }

    .sth-label-value {
      color: $color-font-prompt;
      display: inline-block;
    }
  }
  //添加受益人
  .sth-add-beneficiary {
    display: block;
    height: $height-list-item-sp;
    text-align: center;
    line-height: $height-list-item-sp;
    font-size: $font-size-st;
    color: $color-std;
    margin-bottom: 0.15rem;
    background: $color-cont-bg;
    border-top: $border-size-std;
    width: 100%;
  }

  &.sth-desk-default {
    background: transparent;
    padding-bottom: 0;

    &.sth-desk-default-view {
      input[disabled],
      select[disabled] {
        opacity: 1;
      }
    }

    .sth-panel .sth-panel-body {
      @include container;

      .sth-cell {
        width: 25%;
        float: left;
        margin-bottom: 5px;

        .sth-label {
          padding-left: 5px;
        }

        &.sth-cell-2 {
          width: 50%;

          .sth-label {
            //noinspection CssInvalidFunction
            width: math.div($sth-cell-label-width, 2);
          }

          .sth-label + * {
            //noinspection CssInvalidFunction
            width: 100% - math.div($sth-cell-label-width, 2);
          }
        }

        &.sth-cell-3 {
          width: 75%;

          .sth-label {
            //noinspection CssInvalidFunction
            width: math.div($sth-cell-label-width, 3);
          }

          .sth-label + * {
            //noinspection CssInvalidFunction
            width: 100% - math.div($sth-cell-label-width, 3);
          }
        }

        &.sth-cell-4 {
          width: 100%;

          .sth-label {
            //noinspection CssInvalidFunction
            width: math.div($sth-cell-label-width, 4);
          }

          .sth-label + * {
            //noinspection CssInvalidFunction
            width: 100% - math.div($sth-cell-label-width, 4);
          }
        }

        &.sth-cell-row-grabbed + * {
          clear: both;
        }
      }
    }
  }

  .sth-wizard {
    overflow-x: hidden;
    overflow-y: scroll;

    .page {
      > .page-body {
        padding-top: $page-header-height;
        padding-bottom: $page-footer-height;
      }
      transition: transform 400ms;
      display: block;

      .noheader {
        padding-top: 0;
      }

      &.prev-page {
        transform: translate(-100%, 0%);
        display: none;
      }

      &.current-page {
        transform: translate(0%, 0%);
        display: block;
      }

      &.next-page {
        transform: translate(100%, 0%);
        display: none;
      }

      // &.current-page + .next-page {
      //   display: block;
      // }

      &.hide-page {
        display: none;
      }

      .page-footer {
        display: flex;

        .wizard-step-bottom-button {
          flex: 1;
          text-align: center;
          line-height: $page-footer-height;
          box-shadow: $box-shadow-float-top;
          background: $color-reverse;
          color: $color-std;
          font-weight: 600;
        }
      }
    }
  }
}
//转帐支付声明 add by wendy.gu
.statement {
  background: $color-cont-bg;
  padding: 0 $padding-page;

  .statement-title {
    height: $height-title-sp;
    line-height: $height-title-sp;
    text-align: center;
    color: $color-font-prompt;
    border-bottom: $border-size-std;
  }

  .statement-content {
    color: $color-font-main-title;
    line-height: $height-multi-txt2;
    padding: $padding-container 0;
  }

  .statement-content-t {
    line-height: $height-single-txt;
  }

  .read {
    width: $icon-s-sec-width;
    height: $icon-s-sec-width;
    display: inline-block;
    background: url('../../../images/mobile/checkbox_1.png') no-repeat;
    background-size: cover;
    vertical-align: middle;
    margin-right: $margin-icon-txt;
  }

  .read-ck {
    background: url('../../../images/mobile/checkbox_2.png') no-repeat;
    background-size: cover;
  }

  #read-ck {
    background: url('../../../images/mobile/checkbox_2.png') no-repeat;
    background-size: cover;
  }
}
//自动核保结果
.underwriting-container {
  font-size: $font-size-st;
  width: 100%;
  height: 100%;
  background: $color-cont-bg;

  .underwriting-fail {
    width: 100%;
    padding: $padding-page;
  }

  .title {
    line-height: $height-single-txt;
    margin-bottom: $margin-contaier;
  }

  .promptIcon {
    width: $icon-status-width;
    height: $icon-status-width;
    display: inline-block;
    margin: 5px $padding-container 0 0;
    float: left;
    background: url('../../../images/mobile/icon_notice.png') no-repeat;
    background-size: cover;
  }

  .sucIcon {
    width: $icon-status-width;
    height: $icon-status-width;
    display: inline-block;
    margin: 5px $padding-container 0 0;
    float: left;
    background: url('../../../images/mobile/icon_success.png') no-repeat;
    background-size: cover;
  }

  .failCause {
    border: $border-size-std;
    padding: $padding-container;
    color: $color-font-sub-title;
    word-break: break-all;

    .causeItem {
      line-height: $height-multi-txt2;
      margin: 0;
    }

    .checkItem {
      line-height: $height-multi-txt2;
      margin: 0 0 $margin-txt-txt;
    }
  }

  .radio {
    width: $icon-rdi-width;
    height: $icon-rdi-width;
    display: inline-block;
    float: left;
    margin-right: $margin-icon-txt2;
    background: url('../../../images/mobile/radio_1.png') no-repeat;
    background-size: cover;
  }

  .radio-ck {
    width: $icon-rdi-width;
    height: $icon-rdi-width;
    background: url('../../../images/mobile/radio_2.png') no-repeat;
    background-size: cover;
  }

  .btns-container {
    display: flex;
    width: 100%;
    flex-flow: row;
    margin-top: $margin-search-btn;

    .btn-con {
      flex: 2.5;
    }

    .btn {
      width: -webkit-calc(100% - #{$padding-container} * 2);
      height: $height-st-btn;
      line-height: $height-st-btn;
      border: 1px solid $color-std;
      border-radius: $border-radius-std;
      text-align: center;
      margin: 0 auto;
    }

    .btn1 {
      color: $color-std;
      background: $color-btn-bg2;
    }

    .btn2 {
      color: $color-cont-bg;
      background: $color-std;
    }

    .enable .btn {
      color: $color-cont-bg;
      background: $color-font-disble;
      border: none;
    }
  }

  .status-container2 {
    text-align: center;
    line-height: $height-multi-txt2;
    overflow: hidden;
  }

  .statusImg {
    width: $img-big-status-width;
    height: $img-big-status-width;
    margin: $margin-bigIcon-container auto $margin-contaier2;
    background: url('../../../images/mobile/icon_success_2.png') no-repeat;
    background-size: cover;
  }

  .msg-p {
    margin: 0;
  }

  .status-container2 {
    .btnBack {
      width: calc(100% - #{$padding-page} * 2);
      height: $height-big-btn;
      line-height: $height-big-btn;
      background: $color-btn-bg;
      color: $color-btn-font;
      text-align: center;
      margin: $margin-contaier2 auto;
      border-radius: $border-radius-std;
    }
  }
}
//状态页
.status-container {
  .statusImg {
    width: $img-big-status-width;
    height: $img-big-status-width;
    margin: $margin-bigIcon-container auto $margin-contaier2;
    background: url('../../../images/mobile/icon_success_2.png') no-repeat;
    background-size: cover;
  }

  .statusImgErr {
    width: $img-big-status-width;
    height: $img-big-status-width;
    margin: $margin-bigIcon-container auto $margin-contaier2;
    background: url('../../../images/mobile/icon_fail.png') no-repeat;
    background-size: cover;
  }

  .mainMsg {
    margin: 0;
    color: $color-font-main-title;
    text-align: center;
    font-size: $font-size-span-t;
  }

  .mainDesc {
    margin: 0;
    color: $color-font-sub-title;
    text-align: center;
    font-size: $font-size-st;
    margin-top: $margin-txt-txt;
  }
}
//资料上传温馨提示
.uploadPrompt {
  background: $color-cont-bg;
  padding: $padding-page;
  margin-bottom: $margin-contaier;

  .prompt-title {
    height: $height-title-sp;
    line-height: $height-title-sp;
    color: $color-font-prompt;
    font-size: $font-size-span-t;
  }

  .prompt-content {
    line-height: $height-multi-txt2;
    color: $color-font-main-title;
  }
}
//影像核对
.sth-document-item-show {
  width: 100%;

  .sth-document-image-show {
    width: 100%;
    min-height: 1.8rem;
    border: $border-upload-img;
    padding: $padding-container $padding-page;

    > img {
      width: 100%;
    }
  }

  .sth-document-title-show {
    width: 100%;
    text-align: center;
    color: $color-font-disble;
    margin-top: 0.05rem;
  }
}
//资料上传处
.sth-uploadItem {
  margin-top: $margin-contaier;

  .content {
    width: 100%;
    height: $height-photo-cont;
    line-height: $height-photo-cont;
    text-align: center;
    color: $color-font-disble;
    background-color: $color-photo-bg;
    border: $border-upload-img;
    position: relative;
  }

  .uploadArea {
    position: relative;

    .icon-content {
      position: absolute;
      width: $width-icon-btn-cont;
      //noinspection CssInvalidFunction
      top: math.div(-$width-del-btn, 2);
      //noinspection CssInvalidFunction
      right: math.div(-$width-del-btn, 2);
    }

    .icon {
      float: right;
      width: $width-del-btn;
      height: $height-del-btn;
      background-repeat: no-repeat;
      background-position: center;
      background-size: $icon-size-sp;

      &.icon-close {
        background-image: url('../../../images/mobile/icon_close.png');
      }
    }
  }

  .upload-img {
    width: 100%;
    height: 100%;
    border: $border-upload-img;

    .img-content {
      width: $width-photo-cont;
      margin: 0 auto;

      img {
        width: 100%;
        margin-top: $margin-contaier;
        margin-bottom: $margin-contaier;
        border: none;
      }
    }
  }

  .upload-title {
    width: 100%;
    text-align: center;
    color: $color-font-disble;
  }

  .existImg .content {
    display: none;
  }

  .existImg .upload-img {
    display: block;
  }

  .noImg .content {
    display: block;
  }

  .noImg .upload-img {
    display: none;
  }

  .camera {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    opacity: 0;
  }
}
//添加其他资料
.sth-add-other-image {
  display: inline-block;
  width: 100%;
  height: $height-list-item-sp;
  line-height: $height-list-item-sp;
  text-align: center;
  color: $color-std;
  border-top: $border-size-std;
  margin-bottom: $margin-contaier;
  font-size: $font-size-st;
  background-color: $color-cont-bg;
  margin-top: -$margin-contaier;
}
//签名分享按钮
.sth-share-button-container {
  padding: $padding-container;
  background-color: $color-page-bg;
  overflow: hidden;

  .share-button {
    width: $width-st-btn;
    height: $height-st-btn;
    line-height: $height-st-btn;
    background-color: $color-font-disble;
    color: $color-btn-font;
    border-radius: $border-radius-std;
    text-align: center;
    float: right;
    font-size: $font-size-st;
    display: block;
  }

  .share-ok {
    background-color: $color-btn-bg;
  }

  .button-hidden {
    display: none;
  }
}

.share-button-container-hidden {
  display: none;
}
//设置单证中input的样式
#hintContent {
  input {
    display: inline-block;
    height: 13px;
  }
}
//单证列表项
.sth-document-signature-item {
  padding: $padding-container;
  background-color: $color-cont-bg;
  border-bottom: $border-size-std;
  color: $color-font-main-title;

  .signature {
    width: $icon-s-sec-width;
    height: $icon-s-sec-width;
    display: inline-block;
    background: url('../../../images/mobile/checkbox_1.png') no-repeat;
    background-size: cover;
    vertical-align: middle;
    margin-right: $margin-icon-txt;
  }

  .signature-ck {
    background: url('../../../images/mobile/checkbox_2.png') no-repeat;
    background-size: cover;
  }

  .showInput {
    display: inline-block;
  }

  .hideInput {
    display: none;
  }

  .to-document {
    overflow: hidden;
    float: right;
    width: $arrow-left-right-width;
    height: $arrow-left-right-height;
    background: url('../../../images/mobile/arrow_right.png') no-repeat;
    background-size: cover;
    vertical-align: middle;
  }
}

.signature-item-hidden {
  display: none;
}
//签名提交成功页
.submitSignSuccess-container {
  width: 100%;
  height: 100%;
  background: $color-cont-bg;

  .image-container {
    text-align: center;
    padding-top: $margin-bigIcon-container;

    .icon {
      display: inline-block;
      width: $img-big-status-width;
      height: $img-big-status-width;
      background-repeat: no-repeat;
      background-position: center;
      background-size: $img-big-status-width;

      &.icon-success-2 {
        background-image: url('../../../images/mobile/icon_success_2.png');
      }
    }
  }

  .success-text {
    width: 100%;
    text-align: center;
    font-size: $font-size-span-t;
    margin-top: $margin-icon-txt2;

    .sub-title {
      font-size: $font-size-sub-title;
    }
  }

  .closePage {
    width: $width-big-btn;
    height: $height-big-btn;
    line-height: $height-big-btn;
    margin: $margin-search-btn auto 0;
    text-align: center;
    border-radius: $border-radius-std;
    background: $color-btn-bg;
    color: $color-btn-font;
    display: block;
  }

  .hidden {
    display: none;
  }
}
//分享链接错误页
.shareUrlStatus-container {
  width: 100%;
  height: 100%;
  background: $color-cont-bg;

  .image-container {
    text-align: center;
    padding-top: $margin-bigIcon-container;

    .icon {
      display: inline-block;
      width: $img-big-status-width;
      height: $img-big-status-width;
      background-repeat: no-repeat;
      background-position: center;
      background-size: $img-big-status-width;

      &.icon-fail {
        background-image: url('../../../images/mobile/icon_fail.png');
      }
    }
  }

  .error-text {
    width: 100%;
    text-align: center;
    font-size: $font-size-span-t;
    margin-top: $margin-icon-txt2;
  }
}
//签名页提示信息
.signaturePrompt-content {
  padding: $padding-container;
  background: $color-cont-bg;
  line-height: $height-multi-txt2;
  margin-top: $margin-contaier;

  .confirm {
    width: $icon-s-sec-width;
    height: $icon-s-sec-width;
    display: inline-block;
    background: url('../../../images/mobile/checkbox_1.png') no-repeat;
    background-size: cover;
    vertical-align: middle;
    margin-right: $margin-icon-txt;
  }

  .confirm-ck {
    background: url('../../../images/mobile/checkbox_2.png') no-repeat;
    background-size: cover;
  }
}
//签字按钮
.signButton {
  display: none;

  .signButton-content {
    width: $width-big-btn;
    margin: $margin-contaier auto 0;
    height: $height-big-btn;
    line-height: $height-big-btn;
    text-align: center;
    background-color: $color-sign-button;
    border-radius: $border-radius-std;
    font-weight: 700;
    color: $color-btn-font;
  }

  .enable {
    background-color: $color-font-disble;
  }

  &.signButton-show {
    display: block;
  }
}
//人脸识别 按钮
.btnFacecheck {
  display: block;
  width: $width-big-btn;
  height: $height-big-btn;
  line-height: $height-big-btn;
  background: $color-btn-bg;
  color: $color-btn-font;
  text-align: center;
  border-radius: $border-radius-std;
  margin: 0 auto;
}
//短信身份验证
#message-check-container {
  width: 100%;
  background: $color-cont-bg;
  margin-top: $margin-contaier;

  .phone {
    border-bottom: $border-size-std;
  }

  .verifiItem {
    height: $height-list-item-sp;
    line-height: $height-list-item-sp;
    color: $color-font-main-title;
    width: calc(100% - #{$padding-page});
    margin-left: $padding-page;
    box-sizing: content-box;
  }

  input {
    border: none;
    margin-left: $margin-contaier;
    outline: none;
  }

  .send-verificode-btn {
    width: $width-st-btn;
    height: $height-st-btn2;
    line-height: 0.3rem;
    background: $color-btn-bg;
    color: $color-btn-font;
    text-align: center;
    border-radius: $border-radius-std;
    float: right;
    margin-top: 0.07rem;
    margin-right: 0.2rem;

    &.disable {
      background: $color-font-disble;
    }
  }

  .successPrompt {
    height: $height-title-sp;
    text-align: center;
    margin-top: $margin-contaier2;
    display: none;
  }

  .sucImg {
    display: inline-block;
    width: $icon-status-width;
    height: $icon-status-width;
    margin-right: $margin-contaier;
    vertical-align: middle;
    background: url('../../../images/mobile/icon_success.png') no-repeat;
    background-size: cover;
  }

  .variInput {
    width: calc(100% - 195px);
  }
}
//签名
#signature-html-container {
  width: 100%;
  height: 100%;
  background: $color-cont-bg;

  #signature-html-main {
    width: 100%;
    height: -webkit-calc(100% - 60px);
    padding: 10px;
    line-height: 20px;
    overflow-y: scroll;

    .fontSize13 {
      font-size: 6px !important;
    }

    .fontSize14 {
      font-size: 7px !important;
    }

    .fontSize15 {
      font-size: 7.5px !important;
    }

    .fontSize16 {
      font-size: 8px !important;
    }

    .fontSize18 {
      font-size: 9px !important;
    }

    #policy_id_t {
      font-size: 10px !important;
    }
  }

  .page-content {
    font-size: 0.13rem;
  }
  //健康问题 投被保人同一人
  .onlyOneButton {
    margin: 0 auto 15px;

    .sig-button {
      width: 100%;
      margin-left: 0;
    }
  }

  .threeButton {
    .sig-button {
      width: -webkit-calc(30%);
      margin-left: -webkit-calc(3.3%);
    }
  }

  .fourButton {
    .sig-button {
      margin-bottom: 15px;
    }
  }

  #sig-button {
    width: -webkit-calc(100% - 28px);
    height: 30px;
    margin-bottom: 15px;
  }

  .underLine {
    text-decoration: underline;
  }

  .sig-button {
    width: -webkit-calc(47%);
    height: 30px;
    text-align: center;
    background: #6495ed;
    float: left;
    margin-left: -webkit-calc(2.5%);
    font-weight: 700;
    font-size: 0.14rem;
    color: #fff;
    border-radius: $border-radius-std;
    padding: 0;
    border: none;
  }

  #button-container {
    width: -webkit-calc(100% - 28px);
    height: 45px;
    background: #fff;
    box-shadow: 0 5px 5px 0 rgba(231, 231, 231, 0.75);
    border-radius: $border-radius-std;
    margin: 15px auto 0;

    .insureOrder-left {
      color: #fff;
      background: $color-std;
      font-size: $font-size-st;
      text-align: center;
      float: left;
      border-right: 2px solid white;
      border-radius: $border-radius-std;
      width: -webkit-calc(50%);
      height: 45px;
      line-height: 45px;

      &.enable {
        background: rgb(158, 158, 158);
      }
    }

    .insureOrder-right {
      color: #fff;
      background: $color-std;
      font-size: $font-size-st;
      text-align: center;
      float: left;
      border-left: 2px solid white;
      border-radius: $border-radius-std;
      width: -webkit-calc(50%);
      height: 45px;
      line-height: 45px;

      &.enable {
        background: rgb(158, 158, 158);
      }
    }
  }

  .enable {
    background: rgb(158, 158, 158);
  }

  #backBtn {
    width: $width-big-btn;
    height: 45px;
    line-height: 45px;
    margin: 15px auto 0;
    text-align: center;
    background-color: $color-btn-bg;
    color: $color-btn-font;
    border-radius: $border-radius-std;
  }
}

// 支付信息
// added by yanzhi.mo
.payment {
  font-size: 0.125rem;

  .header {
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        width: 0.375rem;
        flex-basis: 40%;
      }

      .timer {
        flex-basis: 60%;
        color: #6b9ef7;
        text-align: right;
      }
    }

    .order-single-info {
      display: flex;
      flex-direction: row;

      .label {
        flex-basis: 30%;
        color: gray;
      }

      .datum {
        flex-basis: 70%;
      }
    }
  }

  .body {
    .instruction {
      padding: 0.125rem;
      color: gray;
    }

    .bank-selection {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon {
        width: 0.375rem;
      }

      .arrow-right {
        width: 0.15rem;
      }

      ul {
        width: 100%;
        padding-left: 0.1rem;

        li:last-child {
          font-size: 0.1rem;
          color: grey;
        }
      }
    }
  }

  .footer {
  }

  .list {
    background-color: #fff;

    .item {
      border-bottom-color: gainsboro;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      padding: 0.125rem;
    }
  }

  .red {
    color: darkred;
  }
}

.face-recognition {
  padding: 0 $padding-page;

  .face-button {
    display: block;
    line-height: $height-list-item-sp;
    background: $color-btn-bg;
    color: $color-btn-font;
    text-align: center;
    border-radius: $border-radius-std;
  }

  .successPrompt {
    height: $height-title-sp;
    text-align: center;
    margin-top: $margin-contaier2;
  }

  .sucImg {
    display: inline-block;
    width: $icon-status-width;
    height: $icon-status-width;
    margin-right: $margin-contaier;
    vertical-align: middle;
    background: url('../../../images/mobile/icon_success.png') no-repeat;
    background-size: cover;
  }
}

.sth-input-icon {
  width: -webkit-calc(100% - 0.5rem);
}

#sth-input-icon {
  width: -webkit-calc(100% - 0.3rem);
}

.input-ocr-icon {
  width: 0.5rem;
  height: 100%;
  background: url('../../../images/mobile/icon_camera.png') center no-repeat;
  position: absolute;
  top: 0;
  right: 0;
}

.photo-verifivation {
  $height-upload-container: 2rem;

  .upload {
    padding: $padding-page;

    .upload-container {
      margin-bottom: $margin-contaier;
      position: relative;

      .delete {
        height: $icon-size-sp;
        width: $icon-size-sp;
        background: url('../../../images/mobile/icon_close.png') center
          no-repeat;
        background-size: $icon-size-sp;
        position: absolute;
        //noinspection CssInvalidFunction
        top: math.div(-$icon-size-sp, 2);
        //noinspection CssInvalidFunction
        right: math.div(-$icon-size-sp, 2);
        z-index: 49;
      }

      .img-container {
        height: $height-upload-container;
        line-height: $height-upload-container;
        text-align: center;
        border: 0.005rem dashed $color-cont-border;
        background-color: $color-photo-bg;
        color: $color-font-disble;
        position: relative;
        overflow: hidden;

        img {
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          object-fit: contain;
          margin: -1px;
          position: absolute;
          left: 0;
          top: 0;
        }

        input {
          opacity: 0;
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      p {
        margin: 0;
        text-align: center;
        color: $color-font-disble;
        padding: $padding-container 0;
      }
    }
  }
}
.ageColor {
  display: inline-block;
  color: #0000ff;
}
