@charset "UTF-8";
@import '../../../variables.scss';

#loading-container-mask {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1500; // 保持在布局最外层
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

//页面加载中
.page-loading {
  position: relative;
  top: calc(50% - 60px);
  color: #ffffff;
  text-align: center;

  // .loading{ width:$img-loading; height:$img-loading;  background: url(../../../images/mobile/loading.svg) no-repeat;
  //    background-size: cover; margin:0 auto;}
  .loading {
    background: url('../../../images/newloading.gif') no-repeat;
    background-size: cover;
    margin: 0 auto;
    width: $img-loading * 7;
    background-position: center center;
    height: $img-loading;
  }
}
