@import '../../../variables.scss';
$policy-padding-vertical: 8px;
$policy-line-height: 22px;
$icon-size-sp: 30px;
.policy-list {
  display: block;
  position: relative;
  background-color: transparent;
  margin-bottom: 10px;
}

.policy {
  display: block;
  position: relative;
  background-color: $color-reverse;
  padding: $padding-container;
  padding-top: 0;
  @include container;
  &:not(:last-child) {
    margin-bottom: $list-item-gap * 0.5;
  }
  .content {
    display: block;
    position: relative;
    float: left;
    width: 100%;
    font-size: $font-size-st;
    @include container;
    .title {
      overflow: hidden;
      height: $height-title-sp;

      .icon-policy {
        display: block;
        height: $height-title-sp;
        width: $height-title-sp;
        float: left;
        background-size: $icon-size-sp $icon-size-sp;
        background-repeat: no-repeat;
        background-position: left;
        background-image: url('../../../images/mobile/icon_policy.png');
      }

      .indicator {
        color: $color-font-prompt;
        display: block;
        position: relative;
        float: left;
        width: 25%;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: $height-title-sp;
        line-height: $height-title-sp;
      }
      .label-2 {
        color: $color-font-light3;
        display: block;
        position: relative;
        float: left;
        width: 20%;
        height: $height-title-sp;
        line-height: $height-title-sp;
      }

      .text-2 {
        color: $color-font-light;
        display: block;
        position: relative;
        float: left;
        width: 40%;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: $height-title-sp;
        line-height: $height-title-sp;
      }
    }
    .main {
      background: $color-list-cont-bg;
      padding: $padding-container;
      .item {
        overflow: hidden;
        height: $height-single-txt;
        line-height: $height-single-txt;
      }
    }
    .label {
      color: $color-font-light3;
      display: block;
      position: relative;
      float: left;
      width: 25%;
      height: $height-single-txt;
      line-height: $height-single-txt;
    }
    .text {
      color: $color-font-light;
      display: block;
      position: relative;
      float: left;
      width: 75%;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: $height-single-txt;
      line-height: $height-single-txt;
    }
    .label-2 {
      color: $color-font-light3;
      display: block;
      position: relative;
      float: left;
      width: 25%;
      height: $height-single-txt;
      line-height: $height-single-txt;
    }
    .text-2 {
      color: $color-font-light;
      display: block;
      position: relative;
      float: left;
      width: 25%;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: $height-single-txt;
      line-height: $height-single-txt;
    }
    .right {
      text-align: right;
    }
    .red {
      color: $color-font-state;
    }
  }
}
