@use 'sass:math';
@import '../../../variables.scss';
$desk-top-menu-height: 24px;
$desk-top-menu-padding: 16px;
$desk-sub-menu-width: 200px;
$desk-sub-menu-padding-vertical: 5px;

.desk-top-menu {
  display: block;
  position: fixed;
  height: $desk-page-header-height;
  background-color: $desk-color-std;
  width: 100%;
  @include container;

  .logo {
    float: left;
    width: 200px;
    @include container;

    .logo-back,
    .logo-front {
      display: none;
    }

    .logo-middle {
      float: left;
      position: relative;
      left: auto;
      //noinspection CssInvalidFunction
      height: math.div($desk-page-header-height, 2);
      width: 100%;
      background-size: contain;
      //noinspection CssInvalidFunction
      margin-top: math.div($desk-page-header-height, 4);
    }

    .logo-text {
      float: left;
      padding-top: 0;
      padding-bottom: 0;
      color: $color-reverse;
      line-height: $desk-page-header-height;
    }
  }

  .desk-menus {
    display: block;
    position: relative;
    float: left;
    height: $desk-page-header-height;
    line-height: $desk-page-header-height;
    @include container;

    .desk-menu {
      display: block;
      position: relative;
      float: left;
      padding-left: $desk-top-menu-padding;
      padding-right: $desk-top-menu-padding;
      color: $color-reverse;
      font-size: $font-size-m;
      font-weight: 400;
      height: $desk-top-menu-height;
      line-height: $desk-top-menu-height;
      //noinspection CssInvalidFunction
      margin-top: math.div($desk-page-header-height - $desk-top-menu-height, 2);
      //noinspection CssInvalidFunction
      margin-bottom: math.div(
        $desk-page-header-height - $desk-top-menu-height,
        2
      );
      cursor: pointer;

      &.shown {
        .desk-sub-menus {
          display: block;
        }
      }

      .desk-sub-menus,
      .secondary-sub-menus {
        display: none;
        position: absolute;
        background-color: $desk-color-std;
        min-width: $desk-sub-menu-width;
      }

      .desk-sub-menu,
      .secondary-sub-menu {
        display: block;
        position: relative;
        //noinspection CssInvalidFunction
        padding: $desk-sub-menu-padding-vertical
          math.div($desk-top-menu-padding, 2) $desk-sub-menu-padding-vertical
          $desk-top-menu-padding;

        &:hover {
          background-color: lighten($desk-color-std, 10%);
        }
      }

      .desk-sub-menus {
        left: 0;
        z-index: 1;
        padding-top: 10px;
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.258824);

        .desk-sub-menu {
          .direction {
            float: right;
            line-height: $desk-top-menu-height;
          }

          .secondary-sub-menus {
            top: 0;
            //noinspection CssInvalidFunction
            left: calc(100% - #{math.div($desk-top-menu-padding, 2)});
            box-shadow: 3px 0 4px rgba(0, 0, 0, 0.258824),
              -3px 0 4px rgba(0, 0, 0, 0.258824),
              0 3px 4px rgba(0, 0, 0, 0.258824);
          }

          &.shown {
            .secondary-sub-menus {
              display: block;
            }
          }
        }
      }
    }
  }

  .right-menus {
    display: block;
    position: relative;
    float: right;
    height: $desk-page-header-height;
    line-height: $desk-page-header-height;
    @include container;

    > div {
      display: block;
      position: relative;
      float: left;
      color: $color-reverse;
      font-size: $font-size-m;
      font-weight: 400;
      padding-left: $html-padding-horizon;
      padding-right: $html-padding-horizon;
      height: $desk-top-menu-height;
      line-height: $desk-top-menu-height;
      //noinspection CssInvalidFunction
      margin-top: math.div($desk-page-header-height - $desk-top-menu-height, 2);
      //noinspection CssInvalidFunction
      margin-bottom: math.div(
        $desk-page-header-height - $desk-top-menu-height,
        2
      );
      cursor: pointer;

      &.who-am-i {
        cursor: default;
      }
    }
  }
}
