.age-banner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow-y: auto;
  .knowledge-container {
    padding-left: 0.15rem;
    padding-right: 0.15rem;
    line-height: 0.22rem;
    .note-title {
      color: #000000;
      font-size: 0.17rem;
      padding: 0.1rem 0;
      font-weight: bold;
    }
    .note-center {
      text-align: center;
      font-size: 0.19rem;
    }
    .note-blod {
      font-weight: bold;
    }
  }
  .bomNew {
    width: 100%;
    height: 1rem;
    opacity: 0;
  }
}
.bomBtn {
  position: fixed;
  bottom: 0.15rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.has-read {
  width: 40%;
  height: 0.5rem;
  line-height: 0.5rem;
  border: none;
  background: #bb1c22;
  color: #ffffff;
  font-size: 0.2rem;
  border-radius: 0.24rem;
  text-align: center;
}
.has-readNo {
  background: #999999;
}

.freeTd {
  // border: 1px solid #000000;
  text-align: center;
  font-size: 0.06rem;

  // border-collapse: collapse;
}
.freeTdNew {
  width: 30%;
}
