@use 'sass:math';
@charset "UTF-8";
@import '../../variables.scss';
$widget-separator-height: 36px;
$widget-row-en-label-height: 18px;
$widget-row-en-text-height: $row-height - $widget-row-en-label-height;
$widget-row-bottom-gap: 16px;
$widget-menu-item-height: 36px;
$widget-upload-img-height: 100px;
$widget-upload-img-width: 100px;

input::placeholder {
  color: $color-font-placeholder;
}
textarea::placeholder {
  color: $color-font-placeholder;
}

div.validate {
  display: block;
  position: relative;

  &.fail {
    > select,
    > input {
      color: $color-danger;
    }
  }

  input,
  select {
    display: block;
    width: 100%;
  }
  select {
    border: 0.01rem solid $color-menu-border;
  }

  input[type='checkbox'],
  input[type='radio'] {
    width: auto;
  }

  > span.validation-failure {
    display: block;
    position: absolute;
    right: 0;
    top: 100%;
    height: $font-size-m;
    line-height: $font-size-m;
    font-size: $font-size-s4;
    color: $color-danger; // because of min-font-size in chrome
    transform: scale(0.9);
    left: -5%;
    opacity: 0;
    transition: opacity 500ms linear;

    &.shown {
      opacity: 1;
    }
  }

  &.password {
    > input {
      display: inline-block;
      width: calc(100% - 20px);
    }

    > span.pwd-btn {
      display: inline-block;
      color: $color-primary;
      width: 20px;
      cursor: pointer;
    }
  }
}

.menu-group {
  display: block;
  position: relative;
  width: 100%;

  .menu-item {
    display: block;
    position: relative;
    width: 100%;
    height: $widget-menu-item-height;
    background-color: $color-reverse;
    overflow: hidden;

    span.go-deep {
      font-size: $btn-go-deep-font-size;
      transform: scaleX(0.8);
      margin-top: -2px;
    }
  }

  .check-menu-item {
    display: block;
    position: relative;
    width: 100%;
    height: $widget-menu-item-height;
    background-color: $color-reverse;
    padding-left: $html-padding-horizon;
    overflow: hidden;

    &:not(.checked) {
      span.fa {
        display: none;
      }
    }
  }

  .check-menu-item,
  .menu-item {
    cursor: pointer;

    span.check,
    span.go-deep,
    span.icon,
    span.text {
      display: block;
      position: relative;
      float: left;
      height: $widget-menu-item-height;
      line-height: $widget-menu-item-height;
    }

    span.text {
      width: calc(100% - #{$btn-go-deep-width});
      font-size: $font-size;
      color: $color-font-light2;
    }

    span.check,
    span.go-deep,
    span.icon {
      width: $btn-go-deep-width;
      color: $color-font-light3;
      min-height: 1px;
    }

    span.icon {
      text-align: left;
      width: 24px;

      + span.text {
        width: calc(100% - #{$btn-go-deep-width} - 24px);
      }
    }

    hr {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      border: 0;
      border-bottom: 1px solid $color-font-light5;
      margin: 0;
      padding: 0;
    }

    &:last-child {
      hr {
        display: none;
      }
    }
  }
}

.search {
  display: block;
  position: relative;
  @include container;

  input {
    display: block;
    position: relative;
    float: left;
    width: 100%;
    padding-right: calc(#{$line-height} + 20px);
  }

  span {
    display: block;
    position: absolute;
    width: calc(#{$line-height} + 20px);
    height: $row-height;
    line-height: $row-height;
    padding-left: 10px;
    padding-right: 10px;
    color: $color-icon;
    top: 0;
    right: 0;
  }
}

.tab {
  display: block;
  position: relative;
  background-color: $color-reverse;
  @include container;

  .tab-item {
    display: block;
    position: relative;
    float: left;
    text-align: center;

    > .tab-item-label {
      display: block;
      position: relative;
      height: $row-height;
      line-height: $row-height;
      font-size: $font-size-m;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  hr {
    display: block;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 2px;
    border: 0;
    border-top: 2px solid $color-std;
    margin: 0;
    padding: 0;
    transition: left 0.5s ease-in-out;
  }

  .tab-item:first-child:nth-last-child(2),
  .tab-item:first-child:nth-last-child(2) ~ .tab-item,
  .tab-item:first-child:nth-last-child(2) ~ hr {
    width: 100%;
  }

  .tab-item:first-child:nth-last-child(3),
  .tab-item:first-child:nth-last-child(3) ~ .tab-item,
  .tab-item:first-child:nth-last-child(3) ~ hr {
    width: 50%;
  }

  .tab-item:first-child:nth-last-child(4),
  .tab-item:first-child:nth-last-child(4) ~ .tab-item,
  .tab-item:first-child:nth-last-child(4) ~ hr {
    //noinspection CssInvalidFunction
    width: percentage(math.div(1, 3));
  }

  .tab-item:first-child:nth-last-child(5),
  .tab-item:first-child:nth-last-child(5) ~ .tab-item,
  .tab-item:first-child:nth-last-child(5) ~ hr {
    width: 25%;
  }

  .tab-item:first-child:nth-last-child(6),
  .tab-item:first-child:nth-last-child(6) ~ .tab-item,
  .tab-item:first-child:nth-last-child(6) ~ hr {
    width: 20%;
  }

  &.active-0 hr {
    left: 0;
  }

  &.active-1 {
    .tab-item:first-child:nth-last-child(3) ~ hr {
      left: 50%;
    }

    .tab-item:first-child:nth-last-child(4) ~ hr {
      //noinspection CssInvalidFunction
      left: percentage(math.div(1, 3));
    }

    .tab-item:first-child:nth-last-child(5) ~ hr {
      left: 25%;
    }

    .tab-item:first-child:nth-last-child(6) ~ hr {
      left: 20%;
    }
  }

  &.active-2 {
    .tab-item:first-child:nth-last-child(4) ~ hr {
      //noinspection CssInvalidFunction
      left: percentage(math.div(2, 3));
    }

    .tab-item:first-child:nth-last-child(5) ~ hr {
      left: 50%;
    }

    .tab-item:first-child:nth-last-child(6) ~ hr {
      left: 40%;
    }
  }

  &.active-3 {
    .tab-item:first-child:nth-last-child(5) ~ hr {
      left: 75%;
    }

    .tab-item:first-child:nth-last-child(6) ~ hr {
      left: 60%;
    }
  }

  &.active-4 {
    .tab-item:first-child:nth-last-child(6) ~ hr {
      left: 80%;
    }
  }
}

.load-more,
.no-more {
  display: block;
  position: relative;
  line-height: $widget-separator-height;
  height: $widget-separator-height;
  background-color: transparent;
  color: $color-font-light2;
  text-align: center;

  > span {
    display: block;
    position: relative;
  }
}

.list {
  display: block;
  position: relative;
  @include container;
}

.row-text {
  display: block;
  position: relative;
  @include container;
  // 为错误信息留出高度
  padding-bottom: $widget-row-bottom-gap;

  .label {
    display: block;
    position: relative;
    float: left;
    width: 100%;
    height: $widget-row-en-label-height;
    line-height: $widget-row-en-label-height;
    font-size: 0.7em;
  }

  .validate {
    display: block;
    position: relative;
    float: left;
    width: 100%;

    input {
      height: $widget-row-en-text-height;
      padding-top: calc((#{$widget-row-en-text-height} - #{$line-height}) / 2);
      padding-bottom: calc(
        (#{$widget-row-en-text-height} - #{$line-height}) / 2
      );
    }

    // 当校验失败的时候, 补充画一条红线, 遮盖原来的下划线
    &.fail {
      &:before {
        content: '';
        display: block;
        position: absolute;
        border-bottom: 1px solid $color-danger;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: 1;
      }
    }
  }

  .validate + hr {
    display: block;
    position: absolute;
    bottom: $widget-row-bottom-gap;
    left: $html-padding-horizon;
    right: $html-padding-horizon;
    height: 1px;
    border: 0;
    border-top: 1px solid $color-font-light5;
    margin: 0;
    padding: 0;
  }
}

.row-comments {
  display: block;
  position: relative;

  > span {
    display: block;
    position: relative;
  }
}

.bottom-button {
  display: block;
  float: left;
  height: $height-bot-bar;
  line-height: $height-bot-bar;
  text-align: center;
  width: 100%;
  background-color: $color-reverse;
  box-shadow: $box-shadow-float-top;
  position: relative;

  &:last-child {
    color: $color-std;
  }

  &:after {
    content: '';
    height: 33%;
    top: 33%;
    right: 0;
    display: block;
    position: absolute;
    border-right: $border-size-menu;
  }

  &:last-child::after {
    display: none;
  }

  &.twins {
    width: 50%;
    position: relative;

    &:nth-child(2) {
      color: $color-font-menu-ck;
    }
  }

  &.tribble {
    width: 33.33%;
  }

  &.second {
    background-color: $color-border;
    color: lighten($color-std, 20%);
  }
}

.upload-img {
  height: $widget-upload-img-height;
  width: $widget-upload-img-width;
  position: relative;
  background: $color-reverse;
  border: $desk-sec-border;
  margin: 0 auto;

  .img {
    height: 100%;
    width: 100%;
  }

  .upload-button {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
  }
}

//页面标题下的搜索栏
.search-bar {
  width: 100%;
  padding: $padding-search;
  background-color: $color-page-bg;

  .search-content {
    position: relative;
    // width: $width-search-sp;
    height: $height-search-sp;
    // margin: $margin-contaier auto 0;

    .keyword {
      width: 100%;
      height: $height-search-sp;
      line-height: $height-search-sp;
      border-radius: $border-radius-search;
      background-color: $color-cont-bg;
      padding-left: $padding-search;
      padding-right: $padding-search;
      border: $border-size-std;
      -webkit-appearance: none;
    }

    .serach-icon {
      position: absolute;
      width: $width-icon-btn-cont;
      height: 100%;
      top: 0;
      right: 0;

      .my-icon {
        // display: inline-block;
        float: right;
        margin-right: $padding-search;
        width: $icon-size-sp;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: $icon-size-sp;

        &.icon-search-red {
          background-image: url('../../images/mobile/icon_search_red.png');
        }
      }
    }
  }
}

// 中文换到单行, 30:70分割
html.csms-lang-zh,
html.csms-lang-zh-cn,
html.csms-lang-zh-tw {
  .row-text {
    // 由于左右分割, 因此右边一般有位置显示错误信息, 因此不需要下面预留位置
    padding-bottom: 0;

    // 将行高调整为标准行高
    .label {
      width: 30%;
      height: $row-height;
      line-height: $row-height;
      font-size: $font-size;
    }

    // 将行高调整为标准行高
    input {
      height: $row-height;
      padding-top: calc((#{$row-height} - #{$line-height}) / 2);
      padding-bottom: calc((#{$row-height} - #{$line-height}) / 2);
    }

    .validate {
      float: left;
      width: 70%;

      &.fail {
        // 调整行高以后, 提升下划线位置
        &:before {
          bottom: $text-padding-vertical - 2px;
        }
        // 调整行高以后, 提升错误信息位置
        span.validation-failure.shown {
          top: calc(100% - #{$text-padding-vertical - 2px});
        }
      }
    }

    // 调整行高以后, 提升下划线位置
    .validate + hr {
      bottom: $text-padding-vertical - 2px;
    }
  }

  .bottom-button.gap {
    letter-spacing: 16px;
    padding-left: $btn-padding-l + 16px;
  }
}

// 屏幕尺寸320以下, 40:60分割, 以免标签被遮盖
@media (max-width: 320px) {
  .row-text {
    .label {
      width: 40%;
    }

    .validate {
      width: 60%;
    }
  }
}
