@use 'sass:math';
@import '../../../variables.scss';

$product-list-title-height: 36px;
$product-padding-vertical: 8px;
$product-plain-image-width: 100px;
$product-plain-image-height: 74px;
$product-slogan-line-height: 22px;
$product-fav-image-height: 94px;
$product-fav-image-width: 100px;
$product-fav-selected-height: 30px;
$product-fav-selected-width: 30px;
$product-fav-left-region-width: 60px;
$product-price-color: #b03432;
$product-price-start-color: #b6b6b6;

$product-list-height: 0.9rem;
$product-list-image-height: 0.7rem;
$product-list-image-width: 1.1rem;
$product-list-icon-size: 0.15rem;
$product-hot-height: 2.42rem;
$product-hot-image-height: 1.5rem;

.product-list {
  display: block;
  position: relative;
  background-color: $color-cont-bg;
  margin-bottom: $margin-contaier;
  margin-top: $margin-contaier;

  .product-list-title {
    display: block;
    position: relative;
    background-color: $color-cont-bg;
    height: $height-title-sp;
    // border-top: $border-size-std;
    span {
      display: block;
      position: relative;
      height: $height-title-sp;
      line-height: $height-title-sp;
      color: $color-font-sub-title;
      padding: 0 $padding-page;
      width: calc(100% - #{$padding-page} * 2);
      // border-bottom: 1px solid #bb1c22; update by wendy.gu 2018 Mar.5
      margin: 0 auto;

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 5px;
        border-top: 12px solid #bb1c22;
        left: 0;
        top: 38%;
      }

      // &:after {
      //   content: '';
      //   display: block;
      //   position: absolute;
      //   width: 3%;
      //   border-top: 1px solid $color-font-sub-title;
      //   right: 34%;
      //   top: 50%;
      // }
    }
  }
  .right-product-region {
    width: 100%;
    float: left;
    margin-left: 0;
    transition: margin-left 300ms ease-in;
  }

  &.selectable {
    .right-product-region {
      margin-left: $product-fav-left-region-width;
    }
  }
}
.hot-product-list-con {
  width: 100%;
  overflow-x: scroll;
  border-bottom: 0.005rem solid #e2e2e2;
  -webkit-overflow-scrolling: touch;
  .hot-product-list {
    width: 5rem;
  }
}
.product {
  display: block;
  position: relative;
  background-color: $color-reverse;
  padding: $padding-container 0;
  // height: $product-list-height;
  border-bottom: $border-size-std;
  width: calc(100% - #{$padding-page} * 2);
  margin: 0 auto;

  &:last-child {
    border-bottom: 0;
  }
  @include container;

  .img {
    background: $color-reverse;
  }

  &.hot {
    // height: $product-hot-height;//update by wendy.gu 2018 Mar.5
    display: block;
    // flex-direction: column;
    width: 2.5rem;
    padding: 0 10px;
    float: left;
    .content {
      flex: 1;
      height: 0.7rem; //update by wendy.gu 2018 Mar.5
      margin-top: $margin-contaier;

      .title {
        display: block;
        position: relative;
        float: left;
        width: 100%;
        @include container;

        > span {
          // display: block;
          font-size: 0.15rem;
          color: $color-font-light;
          position: relative;
          padding-left: 5px;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          overflow: hidden;
          width: 100%;
          -webkit-box-orient: vertical;
          // &:before {
          //     content: '';
          //     display: block;
          //     position: absolute;
          //     width: 5px;
          //     border-top: 15px solid #bb1c22;
          //     left: 0;
          //     top: 17%;
          // }
        }
      }

      .slogan {
        display: block;
        position: relative;
        float: left;
        width: 100%;

        span {
          display: block;
          padding-left: 5px;
          position: relative;
          line-height: $product-slogan-line-height;
          color: $color-font-sub-title;
          font-size: $font-size-number;
          white-space: nowrap;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      .like {
        display: block;
        position: relative;
        float: left;
        width: 50%;
        font-size: $font-size-number;
        line-height: $height-single-txt;
        clear: both;

        span:last-child {
          float: left;
          line-height: $height-single-txt + 0.02rem;
        }

        .icon {
          width: $product-list-icon-size;
          height: $height-single-txt;
          margin-right: $margin-icon-txt;
          display: block;
          float: left;
          background-repeat: no-repeat;
          background-position: center;
          background-size: $product-list-icon-size;

          &.icon-like {
            background-image: url('../../../images/mobile/icon_like.png');
          }

          &.icon-dislike {
            background-image: url('../../../images/mobile/icon_dislike.png');
          }
        }

        > span.liked + span,
        > span.liked {
          color: $color-std;
        }

        > span > span {
          display: block;
          position: absolute;
          visibility: hidden;
          top: 0;
          left: 0;
          opacity: 0.9;
          line-height: 0.32rem;
          transition: visibility 0s linear, top 0.5s linear, opacity 0.5s linear,
            font-size 0.5s linear;
        }

        &.like-effect-shown > span > span {
          visibility: visible;
          font-size: 0.8rem;
          top: -0.31rem;
          opacity: 0;
        }
      }

      .price {
        display: block;
        position: relative;
        float: left;
        width: 50%;
        text-align: left;
        padding-left: 0.05rem;
        color: $color-font-money;
        font-size: $font-size-price;
        line-height: $height-single-txt;

        span:last-child {
          font-size: $font-size-number;
          margin-left: $margin-icon-txt;
          color: $color-font-main-title;
        }
      }
    }

    .img {
      display: block;
      position: relative;
      // float: left;
      // height: $product-hot-image-height; //update by wendy.gu 2018 Mar.5
      width: 100%;
      overflow: hidden;

      img {
        display: block;
        height: 100%;
        width: 100%;
      }
    }
  }

  &.plain {
    display: flex;

    .img {
      display: block;
      position: relative;
      float: left;
      height: $product-list-image-height;
      width: $product-list-image-width;
      margin-right: $margin-icon-txt2;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .content {
      overflow: hidden;
      flex: 1;
      height: 0.7rem; //$product-list-image-height; update by wendy.gu 2018 Mar.5

      .title {
        display: block;
        position: relative;
        float: left;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        // margin: $margin-dv-sp10 0;

        span {
          font-size: 0.15rem;
          color: $color-font-light;
          // white-space: nowrap;
          // display: block;
          position: relative;
          // display: -webkit-box;
          text-overflow: ellipsis;
          white-space: nowrap;
          -webkit-line-clamp: 1;
          overflow: hidden;
          width: 100%;
          -webkit-box-orient: vertical;
          // &:before {
          //     content: '';
          //     display: block;
          //     position: absolute;
          //     width: 5px;
          //     border-top: 15px solid #bb1c22;
          //     left: 0;
          //     top: 17%;
          // }
        }
      }

      .slogan {
        display: block;
        position: relative;
        float: left;
        width: 100%;

        > span {
          display: block;
          position: relative;
          line-height: $product-slogan-line-height;
          color: $color-font-sub-title;
          font-size: $font-size-number;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .like {
        display: block;
        position: relative;
        float: left;
        width: 50%;
        font-size: $font-size-number;
        line-height: $height-single-txt;
        clear: both;

        span:last-child {
          float: left;
          line-height: $height-single-txt + 0.02rem;
        }

        .icon {
          width: $product-list-icon-size;
          height: $height-single-txt;
          margin-right: $margin-icon-txt;
          display: block;
          float: left;
          background-repeat: no-repeat;
          background-position: center;
          background-size: $product-list-icon-size;

          &.icon-like {
            background-image: url('../../../images/mobile/icon_like.png');
          }

          &.icon-dislike {
            background-image: url('../../../images/mobile/icon_dislike.png');
          }
        }

        > span.liked + span,
        > span.liked {
          color: $color-std;
        }

        > span > span {
          display: block;
          position: absolute;
          visibility: hidden;
          top: 0;
          left: 0;
          opacity: 0.9;
          line-height: 0.32rem;
          transition: visibility 0s linear, top 0.5s linear, opacity 0.5s linear,
            font-size 0.5s linear;
        }

        &.like-effect-shown > span > span {
          visibility: visible;
          font-size: 0.8rem;
          top: -0.31rem;
          opacity: 0;
        }
      }

      .price {
        display: block;
        position: relative;
        float: right;
        width: 50%;
        text-align: right;
        color: $color-font-money;
        font-size: $font-size-price;
        line-height: $height-single-txt;

        span:last-child {
          font-size: $font-size-number;
          margin-left: $margin-icon-txt;
          color: $color-font-main-title;
        }
      }
    }
  }

  &.fav {
    .left-edit-region {
      display: block;
      position: absolute;
      width: $product-fav-left-region-width + $html-padding-horizon;
      left: 0;
      top: 0;
      bottom: 0;

      &:before {
        content: '';
        display: block;
        position: absolute;
        height: $product-fav-selected-height;
        width: $product-fav-selected-width;
        //noinspection CssInvalidFunction
        left: calc(50% - #{math.div($product-fav-selected-width, 2)});
        //noinspection CssInvalidFunction
        top: calc(50% - #{math.div($product-fav-selected-height, 2)});
        border-radius: 100%;
        border: 1px solid $color-border-dark;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        height: $product-fav-selected-height - 10px;
        width: $product-fav-selected-height - 10px;
        left: calc(50% - #{math.div($product-fav-selected-width - 10px, 2)});
        top: calc(50% - #{math.div($product-fav-selected-width - 10px, 2)});
        border-radius: 100%;
        background-color: $color-border-dark;
        opacity: 0;
        transition: opacity 300ms ease-in;
      }

      &.selected:after {
        opacity: 1;
      }
    }

    .img {
      display: block;
      position: relative;
      float: left;
      width: $product-fav-image-width;

      img {
        display: block;
        width: 100%;
        height: $product-fav-image-height;
      }
    }

    .title {
      display: block;
      position: relative;
      float: left;
      width: calc(100% - #{$product-fav-image-width});
      padding-left: 0.1rem;

      > span {
        font-size: 0.15rem;
        color: $color-font-light;
      }
    }

    .slogan {
      display: block;
      position: relative;
      float: left;
      width: calc(100% - #{$product-fav-image-width});
      white-space: nowrap;

      span {
        display: block;
        position: relative;
        line-height: $product-slogan-line-height;
        margin-left: 0.1rem;
        color: $color-font-light2;
        @media (max-width: 320px) {
          font-size: $font-size-s2;
        }
      }
    }

    .price {
      display: block;
      position: relative;
      float: right;
      width: 50%;
      text-align: right;
      color: $product-price-color;
      font-size: $font-size-l;
      line-height: $font-size-l + $font-size;
      height: $font-size-l + $font-size;
      @media (max-width: 320px) {
        font-size: $font-size-m;
      }

      span:last-child {
        font-size: 0.75em;
        color: $product-price-start-color;
        margin-left: 5px;
      }
    }
  }

  &.product-add {
    .img {
      display: block;
      position: relative;
      float: left;
      width: $product-plain-image-width;

      img {
        display: block;
        width: 100%;
        height: $product-fav-image-height;
      }
    }

    .title {
      display: block;
      position: relative;
      float: left;
      width: calc(100% - #{$product-plain-image-width});
      padding-left: 0.1rem;
      display: flex;

      > span {
        font-size: 0.15rem;
        color: $color-font-light;
        flex: 6;
      }

      .checked {
        width: 50px;
        height: 30px;
        flex: 1;
        position: relative;

        > .fa {
          display: none;
          height: 100%;
          width: 100%;
          font-size: $font-size-28;
          text-align: center;
          line-height: 30px;
          color: $color-border-dark;
        }

        > .selected {
          display: block;
          color: $color-check;
        }

        > .notSelected {
          display: block;
        }
      }
    }

    .slogan {
      display: block;
      position: relative;
      float: left;
      width: calc(100% - #{$product-plain-image-width});
      white-space: nowrap;

      span {
        display: block;
        position: relative;
        line-height: $product-slogan-line-height;
        margin-left: 0.1rem;
        color: $color-font-light2;
        @media (max-width: 320px) {
          font-size: 0.12rem;
        }
      }
    }

    .like {
      display: block;
      position: relative;
      float: left;
      width: 50%;
      font-size: $font-size-l;
      line-height: $font-size-l + $font-size;
      height: $font-size-l + $font-size;
      clear: both;
      @media (max-width: 320px) {
        font-size: $font-size-m;
      }

      > span.liked + span,
      > span.liked {
        color: $color-std;
      }

      > span > span {
        display: block;
        position: absolute;
        visibility: hidden;
        top: 0;
        left: 0;
        opacity: 0.9;
        line-height: 0.32rem;
        transition: visibility 0s linear, top 0.5s linear, opacity 0.5s linear,
          font-size 0.5s linear;
      }

      &.like-effect-shown > span > span {
        visibility: visible;
        font-size: 0.8rem;
        top: -0.31rem;
        opacity: 0;
      }
    }

    .price {
      display: block;
      position: relative;
      float: right;
      width: 50%;
      text-align: right;
      color: $product-price-color;
      font-size: $font-size-l;
      line-height: $font-size-l + $font-size;
      height: $font-size-l + $font-size;
      @media (max-width: 320px) {
        font-size: $font-size-m;
      }

      span:last-child {
        font-size: 0.75em;
        color: $product-price-start-color;
        margin-left: 5px;
      }
    }
  }
}
