@import '../../../variables.scss';

$alert-title-color: #aaa;
$popup-alert-max-width: 400px;
$popup-alert-width: 70%;
$popup-alert-top: 35%;
$popup-alert-font-size: 16px;

$alert-content-min-height: 25px;

$button-line-height: 18px;

#mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}

.pop-ease {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.pop-ease.hidden {
  transform: translate3d(0, 100%, 0);
  -webkit-transform: translate3d(0, 100%, 0);
}

.pop-ease.shown {
  bottom: 7px;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.popup-alert {
  display: block;
  text-align: center;
  position: fixed;
  top: $popup-alert-top;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1400;
  max-width: $popup-alert-max-width;
  width: $popup-alert-width;
  font-size: $popup-alert-font-size;
  background-color: white;
  border-radius: 4px;

  .button {
    display: inline-block;
    width: 50%;
    font-size: 16px;
    line-height: $button-line-height;
    height: auto;
    padding: 7.5px 12.5px;
    vertical-align: middle;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    background-color: $color-std;
    border-color: $color-std;
    color: $color-reverse;
  }
}

.popup-alert .alert-title {
  padding: 0 0.1rem;
  font-size: 0.125rem;
  text-align: left;
  color: $alert-title-color;
  font-weight: normal;
}

// .popup-alert .alert-content {
//   min-height: $alert-content-min-height;
//   padding: 15px 20px 15px 22.5px;
//   font-size: $font-size-st;
//   margin-left: 1em;
//   text-indent: -1em;
//   display: -webkit-box;
//   display: inline-block;
//   -webkit-box-pack: center;
//   -webkit-box-align: center;
//   -webkit-box-orient: vertical;
//   overflow-y: scroll;
//   max-height: 3rem;

//   div {
//     text-align: left;
//   }
// }
.popup-alert .alert-content {
  min-height: $alert-content-min-height;
  padding: 0.1rem 0.1rem 0.3rem 0.1rem;
  font-size: $font-size-st;
  margin-left: 0;
  text-indent: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  div {
    text-align: left;
  }
}

.popup-alert .alert-content p {
  margin: 0;
  line-height: 2;
}

.popup-alert .alert-btn {
  border-spacing: 8px;
  width: 100%;
  margin: 0 auto;
  border-collapse: separate;
}

.popup-alert .alert-btn .button {
  width: 100%;
  padding: 0;
  line-height: 2.8;
  border-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.popup-alert .alert-btn .button-no {
  width: 50%;
  line-height: 2.8;
  color: $color-btn-font;
  background-color: $color-font-disble;
  border: none;
  border-bottom-left-radius: 4px;
}

.popup-alert .alert-btn .button-yes {
  width: 50%;
  line-height: 2.8;
  color: $color-btn-font;
  background-color: $color-btn-bg;
  border: none;
  border-bottom-right-radius: 4px;
}
