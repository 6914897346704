.tabBottom {
  position: fixed;
  bottom: 0rem;
  width: 100%;
  z-index: 100;
}
.tabbar {
  width: 100%;
  padding: 0.02rem 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
}
@media only screen and (device-width: 375px) and (device-height: 690px) and (-webkit-device-pixel-ratio: 3) {
  .tabbar {
    padding: 0.2rem 0;
  }
}
/* iphone x */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .tabbar {
    padding: 0.2rem 0;
  }
}
/* iphone xr / 11 */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .tabbar {
    padding: 0.2rem 0;
  }
}
/* iphone xs max / 11 pro max */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .tabbar {
    padding: 0.2rem 0;
  }
}
/* iphone xr / 12 13*/
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .tabbar {
    padding: 0.2rem 0;
  }
}
/* iphone xr / 12pro max 13promax */
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .tabbar {
    padding: 0.2rem 0;
  }
}
.tab-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: PingFang SC;
  font-weight: 400;
  .tab-item-icon {
    width: 0.25rem;
    height: 0.25rem;
    img {
      width: 0.25rem;
      height: 0.25rem;
    }
  }
  .tab-item-name {
    font-size: 0.11rem;
    color: #888888;
  }
}
.tab-active {
  .tab-item-name {
    color: #afa1de;
  }
}
.wrapFooter {
  margin: 0.36rem auto;
  text-align: center;
  font-size: 0.12rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  .footerLogo {
    width: 0.935rem;
    height: 0.26rem;
  }
  .footerOne {
    margin-top: 0.14rem;
    margin-bottom: 0.12rem;
    .footer-phone {
      color: #e43942;
    }
  }
}
.wrapBottom {
  width: 100%;
  height: 0.5rem;
}
