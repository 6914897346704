#tmp_canvas {
	position: absolute;
	left: 0px; right: 100%;
	top: 0px;
	cursor: crosshair;
}
.orange {
	//color: #fef4e9;
    color:#ffffff;
    background:#d54343;
	//border: solid 1px #da7c0c;
	//background: #f78d1d;
	//background: -webkit-gradient(linear, left top, left bottom, from(#faa51a),
	//	to(#f47a20) );
	//background: -moz-linear-gradient(top, #faa51a, #f47a20);
}
#dialog .button {
    border:none;
    -webkit-appearance: none;
	zoom: 1; /* zoom and *display = ie7 hack for display:inline-block */
    margin: 0 2px !important;
	text-align: center;
	padding: .25em 1em .25em !important;
	font-size: 13px;
	//text-shadow: 0 1px 1px rgba(0, 0, 0, .3);
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius:8px;// .5em;
    // width: 78px;
    // height: 22px;
    // line-height: 22px;
}
#mesWindow{
    z-index:8;
    -webkit-border-radius: .5em;
    -moz-border-radius:.5em;
    border-radius:.5em;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
    background:#fff;
    border-radius: .5em;

    width: 80%;
    position: absolute;
    left: 10%;
    top: 30%;
}
#mesWindowTop{
    background-color: rgba(147, 152, 168, 0.20);
    border-bottom:#eee 1px solid;
    margin-left:4px;
    padding:3px;
    font-weight:bold;
    text-align:center;
    font-size:12px;
}

#mesWindowContent{
    background-color: rgba(147, 152, 168, 0.20);
    border-bottom: #d6d6d6 1px solid;
    text-align:center;
    font-size:12px;
    padding: 60px 20px 60px 20px
}

#mesWindow input{
    text-align:center;
    font-size:13px;
}

#mesWindowBottom{
    background-color: rgba(147, 152, 168, 0.20);
    text-align: center;
    padding: 10px 10px 10px 10px
}