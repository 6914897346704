.isShow {
  .addressMask {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
  }

  .item-alert {
    position: fixed; // 不占位
    top: 35%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1400;
    background: #fff;
    width: 85%;
    border-radius: 0.08rem;
    font-family: PingFang SC;
    font-size: 0.13rem;
    box-shadow: 0 0 5px 0 #777;
    font-size: .14rem;
    .item-content {
      padding: 0.1rem 0.1rem 0.3rem 0.1rem;
      .item-address {
        display: flex;
        justify-content: center;
        align-items: center;

        select {
          width: 25%;
          text-align: left;
        }

        input {
          width: 75%;
        }

        .lable-box {
          width: 25%;
        }
      }
    }

    .item-btn {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      .btn-no {
        width: 50%;
        line-height: 3;
        color: #ffffff;
        background-color: #999999;
        border: none;
        border-bottom-left-radius: 4px;
        display: inline-block;
      }

      .btn-yes {
        width: 50%;
        line-height: 3;
        color: #ffffff;
        background-color: #bb1c22;
        border: none;
        border-bottom-right-radius: 4px;
        display: inline-block;
      }
    }
  }

}

.noShow {
  display: none;
}
