#anysign_title {
    font-size: 20pt;
    margin: 0 0 0 0;
    vertical-align: middle;
    text-align: center;
    display: table-cell;
    text-indent: 20px;
}

#btnOK {
    font-size: 10pt;
}

#btnClear {
    font-size: 10pt;
}

#btnCancel {
    font-size: 10pt;
}

#btnContainerOuter {
    margin: 0 0 0 0;
    text-align: center;
    vertical-align: middle;
    //display: table-cell;
}

#btnContainerInner {
}

#container {
    border: 1px solid gray;
    height: 70%;
    width: 99%;
    position: relative;
}

#dialog {

    /*border:1px solid rgba(0,0,0, 0.4);*/
    border-radius: 2px;
}