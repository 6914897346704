@charset "UTF-8";
@import './variables.scss';

.env-mobile:not(.b2c) {
  .page {
    //避免滑动卡顿
    body {
      min-height: 100vh;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
    //避免footer header无法正确定位问题
    .page-header,
    .page-footer {
      position: fixed;
    }

    .page-body {
      //避免滑动卡顿
      min-height: 100vh;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      .wx-my-receipt .no-item,
      .online-visit .no-item {
        min-height: 100vh;
      }
      //签名样式更改 避免签名弹框无法全屏显示
      #signature-html-container {
        #anysign-container {
          position: fixed;

          #dialog {
            height: 100% !important;
            padding-top: 44px;
          }
        }
      }
    }
  }
}
//b2c
.env-mobile.b2c {
  .page {
    //避免滑动卡顿
    body {
      min-height: 100vh;
      overflow-y: auto;
      height: auto !important;
    }
    //避免footer header无法正确定位问题
    .page-header,
    .page-footer {
      position: fixed;
    }

    .page-body {
      //避免滑动卡顿
      min-height: 100vh;
      overflow-y: auto;
      height: auto !important;
      .wx-my-receipt .no-item,
      .online-visit .no-item {
        min-height: 100vh;
      }
      //签名样式更改 避免签名弹框无法全屏显示
      #signature-html-container {
        #anysign-container {
          position: fixed;

          #dialog {
            height: 100% !important;
            padding-top: 44px;
          }
        }
      }
    }
  }
}
//解决安卓机型 签字后无法滑动问题
.env-android.env-mobile.b2c .page.sigScroll .page-body {
  height: 100vh !important;
}

.env-android.env-mobile.b2c .page.sigScroll {
  overflow: auto;
}
