#main {
    height: 1800px;
    padding-top: 90px;
    text-align: center;
}

#fullbg {
    background-color: gray;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    z-index: 3;
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
}

#dialog {
    background-color: #fff;
    /*border:1px solid rgba(0,0,0, 0.4);*/
    height: 100%;
    left: 0;
    top: 0;
    /*margin:-200px 0 0 -200px; */

    position: fixed !important; /* 浮动对话框 */
    position: absolute;

    width: 100%;
    z-index: 5;
    border-radius: 5px;
    // display:none;

    /*
    -webkit-transform:rotate(90deg);
    -moz-transform:rotate(90deg);
    -o-transform:rotate(90deg);
    -ms-transform:rotate(90deg);
    */
}

#dialog p {
    margin: 0 0 12px;
    height: 24px;
    line-height: 24px;
    background: #CCCCCC;
}

#dialog p.close {
    text-align: right;
    padding-right: 10px;
}

#dialog p.close a {
    color: #fff;
    text-decoration: none;
}

.shanshuo {
    animation: shanshuo 1s infinite;
    -webkit-animation: shanshuo 1s infinite;
    display: none;
}

@keyframes shanshuo {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

@-webkit-keyframes shanshuo {
    0% {
        -webkit-opacity: 1
    }
    100% {
        -webkit-opacity: 0
    }
}
