@use 'sass:math';
@import 'variables.scss';
// desktop and ipad and tablet
$desk-form-padding: 10px;

html.env-desktop,
html.env-ipad {
  overflow-y: scroll;

  input,
  select {
    line-height: $line-height;
    height: $desk-row-height;
    padding-top: $desk-text-padding-vertical;
    padding-bottom: $desk-text-padding-vertical;
  }

  div.validate {
    &.fail:after,
    &.success:after,
    &:after {
      line-height: $desk-row-height;
    }

    &.password {
      > span.pwd-btn {
        color: $desk-color-std;
      }
    }
  }
}

.desk-form {
  display: block;
  position: relative;
  @include desk-sec;

  &.no-border {
    border: 0;
  }

  .form-body,
  .form-footer,
  .form-header {
    display: block;
    position: relative;
    @include container;
  }

  .form-header {
    background-color: $desk-color-std;
    color: $color-reverse;
    padding: 0 $desk-sec-padding-horizon;
    line-height: $row-height;
    height: $row-height;

    > span {
      display: block;
      position: relative;
      height: $row-height;
      line-height: $row-height;
      font-size: $font-size-l;
    }
  }

  .form-body {
    padding: $desk-sec-padding-vertical $desk-sec-padding-horizon;
  }

  .form-footer {
    padding: 0 $desk-sec-padding-horizon;

    .form-row {
      @include container;

      .pagination {
        float: left;
      }

      .pagination + .form-button {
        float: right;
        width: auto;
      }

      .form-button a.form-btn {
        min-width: 120px;
      }
    }
  }

  .form-row {
    display: block;
    position: relative;
    // height: $row-height;
    line-height: $line-height;
    float: left;
    width: 100%;

    .form-cell,
    .form-conj-label,
    .form-input,
    .form-label {
      display: block;
      position: relative;
      float: left;
      // height: $row-height;
      line-height: $line-height;
      padding-top: $text-padding-vertical - $desk-text-padding-vertical;
      padding-bottom: $text-padding-vertical - $desk-text-padding-vertical;

      > span {
        display: block;
        position: relative;
        line-height: $line-height;
        height: $desk-row-height;
        padding-top: $desk-text-padding-vertical;
        padding-bottom: $desk-text-padding-vertical;
      }

      > div {
        width: 100%;
      }
    }

    .form-input,
    .form-label {
      > span {
        border-bottom: 1px solid $color-font-light45;
      }

      > div.validate:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: $desk-row-height - 1px;
        right: 0;
        height: 1px;
        border-bottom: 1px solid $desk-color-std;
        z-index: 1;
      }

      > div.validate.disabled:before {
        border-color: $color-font-light45;
      }

      > div.validate.fail:before {
        border-color: $color-danger;
      }
    }

    .form-conj-label {
      > span {
        border-bottom: 1px solid $desk-color-std;
      }
    }

    .form-label {
      width: 30%;
    }

    .form-input {
      width: 70%;
    }

    .form-button {
      display: block;
      position: relative;
      float: left;
      height: $row-height;
      line-height: $line-height;
      width: 100%;
      //noinspection CssInvalidFunction
      padding-top: math.div($row-height - $desk-row-height, 2);
      //noinspection CssInvalidFunction
      padding-bottom: math.div($row-height - $desk-row-height, 2);
    }

    a.form-btn {
      display: block;
      position: relative;
      float: right;
      font-size: $font-size-m;
      height: $desk-row-height;
      line-height: $desk-row-height;
      padding-top: 0;
      padding-bottom: 0;
      border-radius: $border-radius;
      background: none;
      margin-left: 5px;

      &.primary {
        background-color: $desk-color-std;
      }

      &.success {
        background-color: $color-success;
      }

      &.danger {
        background-color: $color-danger;
      }

      &.info {
        background-color: $color-info;
      }

      &.warn {
        background-color: $color-warn;
      }

      &.question {
        background-color: $color-question;
      }

      &.waive {
        background-color: $color-waive;
      }

      &.left {
        float: left;
        margin-left: 0;
        margin-right: 5px;
      }
    }

    &.header {
      // background-color: $color-font-light6;
      .form-cell {
        border-bottom: 2px solid $color-font-light4;
        font-weight: 500;
      }
    }

    &.body {
      &.even:hover,
      &.odd:hover,
      &:hover {
        background-color: $color-font-light5;
      }

      &.even {
        background-color: $color-font-light6;
      }

      .form-cell {
        &.grab-all {
          width: 100%;
        }

        &.no-data {
          text-align: center;
          opacity: 0.7;
        }
      }
    }

    &.double-row {
      .form-label > span {
        height: $row-height * 2 -
          ($text-padding-vertical - $desk-text-padding-vertical) * 2;
      }

      .form-input {
        .validate:last-child {
          margin-top: ($text-padding-vertical - $desk-text-padding-vertical) * 2;
        }
      }
    }

    &.range-row {
      .form-conj-label {
        width: 40px;
        text-align: center;
      }

      .form-input {
        width: calc((70% - 40px) / 2);
      }
    }
  }

  &.single-column {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  &.two-cols {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    .form-row {
      width: 50%;
      padding-left: $desk-form-padding;
      padding-right: $desk-form-padding;

      &.grab-row {
        width: 100%;
        // &:nth-child(2n) {
        // 	padding-left: 0;
        // }

        // &:nth-child(2n+1) {
        // 	padding-right: 0;
        // }
      }

      // &:nth-child(2n) {
      // 	padding-left: $desk-form-padding;
      // }

      // &:nth-child(2n+1) {
      // 	padding-right: $desk-form-padding;
      // }

      &.wrap-row + .form-row {
        clear: both;
      }
    }
  }

  &.four-cols {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    .form-row {
      width: 25%;

      &.grab-row {
        width: 100%;
      }
    }
  }

  &.max {
    min-width: 1200px;
    width: calc(100vw - 30px);
    margin-left: auto;
    margin-right: auto;
  }

  .form-row {
    &.new-row {
      clear: both;
    }
  }

  &.query-form,
  &.two-cols.query-form {
    .form-row {
      width: 100%;

      // &:nth-child(2n) {
      // 	padding-left: 0;
      // }

      // &:nth-child(2n+1) {
      // 	padding-right: 0;
      // }
    }

    .form-body .form-row:last-child {
      border-bottom: 2px solid $color-font-light4;
    }
  }
}
