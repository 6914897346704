.lead-banner{position: fixed;top: 0;left: 0;z-index: 200;width: 100%;height: 100%;
	.swiper-container{z-index: 200;}
	.swiperBanner{}
	.swiper-slide{width: 100%;height: 100%;float: left;position: relative;overflow: hidden;
		.leftOne{width: 100%;height: 100%;}
	}
	.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
	 line-height: 0;
	}
	.swiper-pagination-bullet-active{
		--swiper-pagination-color: #E33049;
	}
	.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
		bottom: 0.3rem;
	}
	.slideJump{width: 0.41rem;height: 0.2rem;line-height: 0.2rem;background: #000000;opacity: 0.2;border-radius: 0.1rem;position: absolute;right: 0.27rem;top: 0.25rem;font-size: 0.12rem;font-weight: 400;color: #FFFFFF;text-align: center;}
	.slideExp{width: 30%;height: 0.4rem;line-height: 0.4rem;background: #E43942;border-radius: 0.2rem;font-size: 0.17rem;font-family: PingFang SC;font-weight: 400;color: #FFFFFF;text-align: center;position: absolute;margin-left: 35%;bottom: 0.6rem;}
	.slideExpNo{display: none;}	
}
.leadMsk{
	position: fixed;top: 0;left: 0;z-index: 109;width: 100%;height: 100%;background-color: #333333;opacity: 0.3;
}