@import '../../../variables.scss';
$top-bar-btn-width: $mobile-top-bar-btn-width;
$top-bar-btn-font-size: $mobile-top-bar-btn-icon-font-size;

.top-bar {
  display: block;
  position: relative;
  background-color: $color-std;
  height: $page-header-height;
  @include container;

  .left-btns,
  .right-btns {
    display: block;
    position: absolute;
    background-color: transparent;
    height: $page-header-height;
    top: 0;
    padding-left: $padding-page;
    z-index: 1;
    @include container;
  }

  .left-btns {
    left: 0;
  }

  .right-btns {
    right: 0;
  }

  .btn {
    display: block;
    position: relative;
    float: left;
    height: $height-top-bar;
    line-height: $height-top-bar;
    text-align: center;
    color: $color-reverse;
    font-size: $color-btn-font;
    padding-right: $padding-page;

    span {
      display: block;
      position: relative;
      height: $page-header-height;
      line-height: $page-header-height;
      width: 100%;
    }
  }

  .back {
    font-size: $top-bar-btn-font-size;

    span {
      // adjust to vertical middel
      margin-top: -2px;
    }
  }

  .title {
    display: block;
    position: relative;
    float: left;
    font-size: $font-size-l;
    color: $color-reverse;
    text-align: center;
    height: $page-header-height;
    line-height: $page-header-height;
    width: 100%;
  }
}
