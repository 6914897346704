@use 'sass:math';
@import '../../../variables.scss';
$bottom-menu-label-height: 0.12rem;

.bottom-menu {
  display: block;
  position: relative;
  background-color: $color-reverse;
  box-shadow: $box-shadow-float-top;
  @include container;

  .item {
    display: block;
    position: relative;
    float: left;
    text-align: center;
    min-height: 1px;

    .icon {
      display: block;
      position: relative;
      width: 100%;
      margin-top: 3px;
      margin-bottom: 3px;
      height: 29px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto 100%;
      color: lighten($color-font-light2, 5%);
    }

    .label {
      display: block;
      position: relative;
      line-height: $bottom-menu-label-height;
      height: $bottom-menu-label-height;
      width: 100%;
      margin-bottom: 1px;
      font-size: $bottom-menu-label-height;
      color: $color-font-light;
      transform: scale3d(0.8, 0.8, 0.8);
    }

    &.active .label {
      color: $color-std;
    }

    &.home {
      .icon {
        font-size: 2em;
        line-height: 29px;
        // background-image: url("../../../images/home.png");
      }

      &.active .icon {
        color: $color-std;
        // background-image: url("../../../images/home-active.png");
      }
    }

    &.category {
      .icon {
        font-size: 1.8em;
        line-height: 29px;
        margin-top: 4px;
        margin-bottom: 2px;
        // background-image: url("../../../images/category.png");
      }

      &.active .icon {
        color: $color-std;
        // background-image: url("../../../images/category-active.png");
      }
    }

    &.explore {
      .icon {
        font-size: 1.8em;
        line-height: 29px;
        // background-image: url("../../../images/explore.png");
      }

      &.active .icon {
        color: $color-std;
        // background-image: url("../../../images/explore-active.png");
      }
    }

    &.me {
      .icon {
        font-size: 1.8em;
        line-height: 29px;
        // background-image: url("../../../images/me.png");
      }

      &.active .icon {
        color: $color-std;
        // background-image: url("../../../images/me-active.png");
      }
    }

    &.salient {
      span {
        font-size: 2.4em;
        color: $color-reverse;
        border-radius: 50%;
        height: 70px;
        width: 70px;
        line-height: 64px;
        position: absolute;
        top: -12px;
        left: calc(50% - 35px);
        display: block;
        background-color: $color-std;
        box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.5);
      }
    }
  }

  .item:first-child:last-child {
    width: 100%;
  }

  .item:first-child:nth-last-child(2),
  .item:first-child:nth-last-child(2) ~ .item {
    width: 50%;
  }

  .item:first-child:nth-last-child(3),
  .item:first-child:nth-last-child(3) ~ .item {
    //noinspection CssInvalidFunction
    width: percentage(math.div(1, 3));
  }

  .item:first-child:nth-last-child(4),
  .item:first-child:nth-last-child(4) ~ .item {
    width: 25%;
  }

  .item:first-child:nth-last-child(5),
  .item:first-child:nth-last-child(5) ~ .item {
    width: 20%;
  }
}
