@import '../../../variables.scss';

#toast-container {
  z-index: 1;
}

.toast {
  display: block;
  position: fixed;
  top: 100%;
  @include for-size(tablet-portrait-up) {
    top: 0; // bottom: 100%;
  }
  left: 10%;
  width: 80%;
  @include for-size(tablet-landscape-up) {
    width: 600px;
    left: calc(50% - 300px);
  }
  opacity: 0.7;
  border-radius: $border-radius;
  @include for-size(tablet-portrait-up) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  color: $color-reverse;
  background-color: black;

  > div {
    display: block;
    position: relative;
    padding: 5px;

    &.more-toast-items {
      font-size: $font-size-s2;
      color: $color-warn;
      opacity: 1;

      hr {
        display: none;
      }
    }
  }

  > div hr {
    display: block;
    position: absolute;
    border: 0;
    border-bottom: 3px solid lighten($color-danger, 20%);
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
  }

  > div.start hr {
    right: 100%;
    transition: right 1.7s linear 0.3s;
  }

  @include for-size(tablet-portrait-up) {
    > div.start hr {
      right: 100%;
      transition: right 2.7s linear 0.3s;
    }
  }

  &.toast-shown {
    // opacity: 0;
    top: calc(50% - #{$line-height} / 2);
    transition: top 0.3s linear; //, opacity 0.3s linear 2s;
    @include for-size(tablet-portrait-up) {
      // fix: iPad显示bug
      //top: $desk-page-header-height;
      transition: top 0.3s linear; //, opacity 0.3s linear 3s;
    }
  }

  .toast-icon {
    display: block;
    position: relative; // float: left;
    line-height: $line-height;
    font-size: 1.2em;
    width: $line-height * 1.5;
    float: left;

    &.fa-question-circle {
      color: $color-question;
    }

    &.fa-warning {
      color: $color-warn;
    }

    &.fa-info-circle {
      color: $color-info;
    }

    &.fa-times-circle {
      color: $color-error;
    }

    &.fa-check-circle {
      color: $color-success;
    }

    &.toast-icon-hidden {
      display: none;

      & + .csms-toast-text {
        text-align: center;
        width: 100%;
      }
    }
  }

  .toast-text {
    display: inline-block;
    position: relative; // float: left;
    width: calc(100% - #{$line-height} * 1.5);
  }
}
