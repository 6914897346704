/*cssId:BASE*/
.custom-breadCrumb {
  background: white;
  padding: 0.22rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 0.09rem;
  .bread_item {
    position: relative;
    width: 0.81rem;
    &:not(:last-child)::after {
      content: '';
      display: block;
      width: 0.25rem;
      height: 0.02rem;
      background: rgb(214, 214, 214);
      position: absolute;
      top: calc(0.31rem / 2 - 0.02rem / 2);
      right: 0;
    }
    &:not(:first-child)::before {
      content: '';
      display: block;
      width: 0.25rem;
      height: 0.02rem;
      background: rgb(214, 214, 214);
      position: absolute;
      top: calc(0.31rem / 2 - 0.02rem / 2);
      left: 0;
    }

    .title {
      text-align: center;
    }
    .cc {
      font-size: 0.15rem;
      font-weight: bold;
      color: rgba(214, 214, 214, 1);
      margin: 0 auto;
      margin-bottom: 0.1rem;
      text-align: center;
      width: 0.31rem;
      height: 0.31rem;
      border: 0.025rem solid rgb(214, 214, 214);
      border-radius: 50%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
    }
    &.active {
      .title {
        color: #e43942;
      }
      .cc {
        color: #e43942;
        border-color: #e43942;
      }
      &::after {
        background: #e43942;
      }
      &::before {
        background: #e43942;
      }
    }
  }

  &::after {
    display: block;
    height: 0.09rem;
    background: #f7f7f7;
    width: 100%;
    content: '';
    border-top: 1px solid #e3e3e3;
    position: absolute;
    left: 0;
    bottom: -0.09rem;
  }
}
