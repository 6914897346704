@use 'sass:math';
@charset "UTF-8";
// media query
// as 0-599, 600-899, 900-1199, 1200-1799, 1800+
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-portait-only {
  @media (min-width: 600px) and (max-width: 899px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-tablet-landscape-only {
  @media (min-width: 900px) and (max-width: 1199px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-desktop-only {
  @media (min-width: 1200px) and (max-width: 1799px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-size($range) {
  $phone-upper-boundary: 600px;
  $tablet-portrait-upper-boundary: 900px;
  $tablet-landscape-upper-boundary: 1200px;
  $desktop-upper-boundary: 1800px;

  @if $range == phone-only {
    @media (max-width: #{$phone-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == tablet-portrait-up {
    @media (min-width: $phone-upper-boundary) {
      @content;
    }
  } @else if $range == tablet-portait-only {
    @media (min-width: $phone-upper-boundary) and (max-width: #{$tablet-portrait-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == tablet-landscape-up {
    @media (min-width: $tablet-landscape-upper-boundary) {
      @content;
    }
  } @else if $range == tablet-landscape-only {
    @media (min-width: $tablet-portrait-upper-boundary) and (max-width: $tablet-landscape-upper-boundary - 1px) {
      @content;
    }
  } @else if $range == desktop-up {
    @media (min-width: $tablet-landscape-upper-boundary) {
      @content;
    }
  } @else if $range == desktop-only {
    @media (min-width: $tablet-landscape-upper-boundary) and (max-width: $desktop-upper-boundary - 1px) {
      @content;
    }
  } @else if $range == big-desktop-up {
    @media (min-width: $desktop-upper-boundary) {
      @content;
    }
  }
}
@mixin btn-big() {
  padding-left: $btn-padding-l;
  padding-right: $btn-padding-l;
}
@mixin btn-form() {
  @include btn-big;
  // background: linear-gradient(90deg, #fc0 0, #fc0 42%, #f58220 100%, #f58220 100%);
  background: linear-gradient(
    90deg,
    #34c2f5 0,
    #34c2f5 42%,
    #87cefa 100%,
    #87cefa 100%
  );
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
@mixin container() {
  &:before {
    content: '';
    display: table;
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
@mixin desk-sec() {
  border: $desk-sec-border;
  border-radius: $border-radius * 2;
}
//add by wendy.gu 2017 Oct.11
//***************************************以下最新定义*****************************************
$html-font-size: 100px;

//padding
$padding-page: 0.15rem; //页面左右内边距
$padding-search: 0.1rem; //搜索栏padding
$padding-container: 0.1rem; //容器上下内边距

$font-size-letter-navi: 0.1rem; //客户导入-字母导航

$font-size-number: 0.12rem; //数字 副标题 如：转发数等
$font-size-price: 0.16rem; //价格
$font-size-st: 0.14rem; //标准字号 如：普通文本、图标字号、按钮‘上一步’‘下一步’‘查询’‘确认’等
$font-size-sub-title: 0.12rem; //副标题字号
$font-size-span-t: 0.17rem; //panel标题、TAB标题
$font-size-page-t: 0.19rem; //页面标题
$font-size-balance: 0.5rem; //我的钱包－余额
//图标大小
$icon-big-size-sp: 0.3rem; //列表项图标大小
$icon-size-sp: 0.2rem; //列表项图标大小
$icon-arrow-size-sp: 0.1rem; //箭头图标大小
$icon-panel-size-sp: 0.45rem; //大图标
//height line-height
/*桌面端*/
$height-main-banner-desk: 0.64rem; //主页banner高
/*移动端*/
$height-main-banner: 1.55rem; //主页banner高
$height-top-status-bar: 0.2rem; //顶部时间状态栏预留高度
$height-top-bar: 0.44rem; //顶部高
$height-bot-bar: 0.48rem; //底部高
$height-search-sp: 0.3rem; //搜索栏框高
$height-tab: 0.4rem; //tab标签筛选栏高度  例：产品中心tab
$height-title-sp: 0.45rem; //大标题层高，行高一致  例：主页‘本季热销’
$height-list-item-sp: 0.45rem; //列表项层高，行高一致 例：电子签名列表
$height-list-item-sp2: 0.4rem; //无间隔列表项层高，行高一致 例：产品详情 产品组成列表
$height-list-item-sp3: 0.6rem; //客户导入list
$height-input: 0.45rem; //输入框高 所有录入项
$height-radio: 0.2rem; //单选按钮宽高
$height-single-txt: 0.3rem; //单行文本行高 例：主页产品标题与描述
$height-molti-txt: 0.28rem; //多行文本行高
$height-multi-txt2: 0.22rem; //多行文本行高  例：电投－转账声明
$height-cont-in-title: 0.4rem; //容器内 标题行高 例：客户保全查询结果、客户建议书
//width
$width-top-bar-icon1: 0.4rem; //顶部 栏内图标层宽
$width-pro-big-img: calc(100% - #{$padding-page} * 2); //产品列表 大图宽
$width-pro-list-img: 1.1rem; //产品列表 缩略图宽
$height-pro-list-img: 0.7rem; //产品列表 缩略图高
$width-category-tab: 1rem;
$width-search-sp: calc(100% - #{$padding-search} * 2); //搜索栏框宽
//按钮width height
$width-big-btn: calc(100% - #{$padding-page} * 2); //大按钮 宽
$height-big-btn: 0.3rem; //大按钮 高
$width-st-btn: 1rem; //标准按钮（6字以上）宽  例：电子签名页分享按钮
$height-st-btn: 0.3rem; //标准按钮高
$width-st-btn2: 0.8rem; //标准按钮（5字以内）宽
$height-st-btn2: 0.3rem; //标准按钮（5字以内）宽
$height-index-btn: 0.14rem; //客户管理index按钮
$width-import-btn: 0.6rem; //导入客户 宽  保全查询：‘网上办理’‘柜面办理’等
$height-import-btn: 0.3rem; //导入客户 高
$width-photo-cont: calc(100% - #{$padding-page} * 2); //影像上传图片容器宽
$height-photo-cont: 2rem; //影像上传图片容器高
$width-del-btn: 0.2rem; //删除按钮 宽 例：影像上传
$height-del-btn: 0.2rem; //删除按钮 高
$width-icon-btn-cont: 0.5rem; //图标按钮容器 宽 注：比正常图标大，放大手机端触点。
$width-category-tab-btn: 0.75rem;
$step-height: 0.92rem; //步骤栏高度
$step-item-height: 0.32rem; //步骤项高度
//padding
$padding-page: 0.15rem; //页面左右内边距
$padding-search: 0.1rem; //搜索栏padding
$padding-container: 0.1rem; //容器上下内边距
$padding-input: 0.05rem; //input左内距
//margin
$margin-search: 0.05rem; //搜索栏与上面容器距离
$margin-search-btn: 0.3rem; //大号按钮与容器间距  例：理赔查询、保全查询
$margin-contaier: 0.1rem; //容器与容器间距	 产品列表
$margin-contaier2: 0.2rem; //容器与容器间距	 产品筛选
$margin-contaier-container: 0.15rem; //容器到page-body底部
$margin-input: 0.1rem; //输入框间距
$margin-dv-sp10: 0.05rem; //容器到文本域上下间距
$margin-txt-txt: 0.1rem; //文本块与文本块间距  例：自助理赔 成功提交页
$margin-icon-txt: 0.05rem; //小图标与文本间距 如 点赞量
$margin-icon-txt2: 0.1rem; //中图标与文本间距 如 产品详情 文字说明性质的图
$margin-bigIcon-container: 0.4rem; //大图标距离容器 例：投保提交
//color
$color-std: #bb1c22; //标准颜色
$color-page-bg: #eeeeee; //标准页面背景
$color-cont-bg: #ffffff; //标准容器背景
$color-btn-bg: #bb1c22; //标准操作按钮背景颜色
$color-btn-font: #ffffff; //标准操作按钮字体颜色

$color-photo-bg: #f2f2f2; //影像上传图片容器背景
$color-cont-border: #e2e2e2; //标准容器 上下边框颜色
$color-menu-border: #cdcdcd; //深色border
$color-btn-enabled: #bb1c22; //状态可变按钮  可编辑  例：我的订单
$color-list-cont-bg: #f7f7f7; //列表 内容区间背景色 例：客户建议书列表、客户保单查询列表、客户保全查询列表
$color-list-tab-bg: #f4f4f4; //列表tab 我的客户
$color-catecory-tab-bg-bg: #f2f2f2; //分类tab-btn
$color-catecory-tab-btn-select: #ffeaea; //分类tab-btn选中
$color-font-main-title: #333333; //主标题  例：主页 发现
$color-font-sub-title: #666666; //副标题
$color-font-disble: #999999; //当前禁用、弱提示 例：tab项未选中字色、我的订单禁用字色、底部menu字色
$color-font-prompt: #bb1c22; //提示性文字 例：支付信息页 ‘支付声明’
$color-font-state: #bb1c22; //订单状态、理赔状态 例：待提交  立案中
$color-font-money: #bb1c22; //金额颜色 例：￥100
$color-font-tab-ck: #bb1c22; //tab选中字色
$color-font-menu-ck: #bb1c22; //导航菜单 选中色 例：主页底部菜单
$color-font-placeholder: #999999; //输入框提示文字色
$color-font-top-title: #ffffff; //页面顶部标题色
$color-balance-font: #ffffff; //我的钱包 余额字色
$color-font-time: #74b5e4; //时间
$color-font-prev: #999999; //‘上一步’按钮字色
$color-font-next: #bb1c22; //‘下一步’按钮字色
$color-btn-bg2: #ffeaea; //按钮浅色背景
$color-mask-bg: #000000; //遮罩层背景色
$color-sign-button: #6495ed; //按钮浅色背景
$step-ck-button: #c49e62; //步骤进过颜色
//border
$border-size-menu: 0.01rem solid $color-menu-border; //深色border 主页底部导航菜单border
$border-size-std: 1px solid $color-cont-border; //标题边框
$border-size-state: 0.01rem solid $color-font-state; //例如导入客户，理赔状态边框
$border-radius-std: 4px; //圆角标准
$border-bottom-size-tab: 0.01rem solid $color-btn-bg; //tab下边框
$border-left-size-tab: 0.02rem solid $color-btn-bg; //tab左边框
$border-select-size-tab: 0.005rem solid $color-std; //tab-btn选中
//noinspection CssInvalidFunction
$border-radius-search: math.div($height-search-sp, 2); //关键字搜索框边框
$border-upload-img: 0.01rem dashed $color-menu-border; //影像上传区域边框

$box-shadow-float-top: 0 -2px 0px rgba(0, 0, 0, 0.258824);

//radio
$sth-input-height: 0.28rem; //页面左右内边距
$sth-radio-icon-size: 0.2rem; //搜索栏padding
$sth-radio-icon-inner-size: 0.1rem; //容器上下内边距

/*something*/
//height
$sth-product-info-header-height: 0.6rem;
$sth-product-info-header-logo-height: 0.35rem;
//width
$sth-product-info-header-logo-width: 1.12rem;
//padding
$sth-product-info-header-padding: 0.12rem;

$box-shadow-float-top: 0 -2px 3px rgba(0, 0, 0, 0.258824);

//以下图标标准
$img-txt-l-btn: 0.46rem; //大图文按钮宽高 例：主页
$img-txt-m-btn: 0.3rem; //大图文按钮宽高 例：用户中心页
$img-txt-s-btn: 0.22rem; //小图文按钮宽高 例：底部按钮
$img-log-width: 1.17rem; //logo宽
$img-log-height: 0.35rem; //logo高
$icon-status-width: 0.2rem; //状态图标宽高
$img-search-btn: 0.16rem; //搜索图标宽高
$img-loading: 0.3rem; //loading图标宽高
$icon-ck-width: 0.15rem; //checkbox小图标 宽高
$icon-s-sec-width: 0.15rem; //小选中图标 宽高 例：通讯录导入
$icon-m-sec-width: 0.18rem; //大选中图标 宽高
$icon-rdi-width: 0.21rem; //单选按钮 宽高
$arrow-up-down-width: 0.17rem; //上下箭头符号 宽
$arrow-up-down-height: 0.1rem; //上下箭头符号 高
$arrow-left-right-width: 0.1rem; //上下箭头符号 宽
$arrow-left-right-height: 0.17rem; //上下箭头符号 高
$img-big-status-width: 0.68rem; //大号状态图标 例：核保结果页
//*************************************以下待删除定义******************************************

// font
$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif, 'Microsoft YaHei';
$font-size-s6: 0.08rem;
$font-size-s5: 0.09rem;
$font-size-s4: 0.1rem;
$font-size-s3: 0.11rem;
$font-size-s2: 0.12rem;
$font-size-s: 0.13rem;
$font-size: 0.14rem;
$font-size-m: 0.16rem;
$font-size-m2: 0.17rem;
$font-size-l: 0.18rem;
$font-size-20: 0.2rem;
$font-size-22: 0.22rem;
$font-size-28: 0.28rem;
$font-size-308: 0.308rem;
$font-size-35: 0.35rem;
$font-size-40: 0.4rem;

$line-height: 0.2rem;
$line-height-px: 20px;

// $color-std:                                  #ff9414;
// $color-std:                                 #34c2f5;    //#1e90ff, #87cefa
$color-reverse: #ffffff;
$color-font: #363636;
$color-font-light: #515151;
$color-font-light2: #666666;
$color-font-light3: lighten($color-font-light2, 20%);
$color-font-light4: lighten($color-font-light3, 20%);
$color-font-light45: lighten($color-font-light3, 25%);
$color-font-light5: lighten($color-font-light4, 10%);
$color-font-light6: lighten($color-font-light5, 5%);
$color-font-light7: lighten($color-font-light6, 3%);
$color-body-bg: #f1f1f1;
$color-border: #eeeeee;
// $color-border-light:                     lighten($color-border, 15%);
// $color-border-light2:                        lighten($color-border, 30%);
$color-border-dark: darken($color-border, 15%);
$color-border-dark2: darken($color-border, 30%);
$color-border-dark3: darken($color-border, 45%);
$color-icon: #9f9f9f;
$color-error: #c22000;
$color-danger: #c22000;
$color-question: #b241d4;
$color-warn: #f7ba2a;
$color-info: #50bfff;
$color-success: #13ce66;
$color-primary: #0087c9;
$color-waive: #919191;
$color-hint: #fe1c1c;
$color-check: #fcb44d;

$border-radius: 2px;
$box-shadow-float-bottom: 0 2px 2px rgba(0, 0, 0, 0.2);

$html-font-size: 100px;
$html-padding-horizon: 16px;

$body-margin: 0;
$body-padding: 0;
$body-bg: linear-gradient(
  180deg,
  #dedede 0,
  #dedede 0,
  #f8f8f8 51%,
  #f8f8f8 100%
);
$body-list-top-gap: 12px;

$row-height: 44px;
$text-padding-vertical: 12px;
$text-border-color: #c9c9c9;
$text-border: 1px solid $text-border-color;

$btn-padding-l: 16px;
$btn-go-deep-width: 40px;
$btn-go-deep-font-size: 2.2em;

$list-item-gap: 8px;
$page-slide-time: 400ms;

$page-header-z-index: 49;
$page-footer-z-index: 49;
$sth-step-in-content-z-index: 50;

$title-font-color: #1b1b1b;
$title-font-size: 0.24rem;

$page-header-height: 44px;
$page-header-bg: linear-gradient(
  180deg,
  #dedede 0,
  #dedede 0,
  #f8f8f8 51vh,
  #f8f8f8 100vh
);
$page-header-2-bg: linear-gradient(
  180deg,
  #dedede #{-$page-header-height},
  #dedede #{-$page-header-height},
  #f8f8f8 51vh,
  #f8f8f8 100vh
);
$page-footer-height: 48px;

// desktop variables
$desk-page-header-height: 64px;
$desk-color-std: darken(#0087c9, 10%);
$desk-page-footer-bg-color: #efeae1;

$desk-sec-padding-vertical: 5px;
$desk-sec-padding-horizon: 10px;
$desk-sec-border: 1px solid $desk-color-std;

$desk-row-height: 32px;
//noinspection CssInvalidFunction
$desk-text-padding-vertical: math.div($desk-row-height - $line-height-px, 2);

$mobile-top-bar-btn-icon-font-size: 0.3rem;
$mobile-top-bar-btn-width: 40px;

.dplayer-mobile-play {
  background: #000;
}
