@use 'sass:math';
@import '../../../variables.scss';
$pagination-border: 0;
.pagination {
  display: block;
  position: relative;
  //noinspection CssInvalidFunction
  padding-top: math.div($row-height - $desk-row-height, 2);
  //noinspection CssInvalidFunction
  padding-bottom: math.div($row-height - $desk-row-height, 2);
  @include container;
  .pagination-btn {
    display: block;
    position: relative;
    float: left;
    border-top: $pagination-border;
    border-bottom: $pagination-border;
    color: $desk-color-std;
    cursor: pointer;
    span {
      display: block;
      position: relative;
      text-align: center;
      line-height: $line-height;
      height: $desk-row-height;
      padding-top: $desk-text-padding-vertical;
      padding-bottom: $desk-text-padding-vertical;
      width: $desk-row-height;
      font-size: $font-size-m;
    }
    span.fa {
      font-size: $font-size-l;
      font-weight: bold;
    }
    &.current {
      text-decoration: underline;
    }
    &:first-child {
      border-left: $pagination-border;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    &:last-child {
      border-right: $pagination-border;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
    &:first-child,
    &:last-child {
      span.fa {
        font-size: $font-size;
        font-weight: normal;
      }
    }
    &:not(.ellipsis):hover {
      transform: scale(1.2);
    }
  }
}