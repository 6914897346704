.service-onlineNew {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  transform-origin: 0 0;
  // width: .68rem;
  height: 0.8rem;
  user-select: none;
  outline: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 900;
  .service-online-icon {
    width: inherit; //.3rem;
    height: inherit; //.3rem;
    pointer-events: none;
    transform: translateY(-0.07rem);
  }
}
