@import '../../../variables.scss';

$logo-width: 100px;
$logo-height: 70px;
$logo-text-padding: 10px;

.logo {
  display: block;
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  .logo-front {
    display: block;
    position: absolute;
    left: calc(50% - 40px);
    width: $logo-width;
    height: $logo-height;
    background-image: url(../../../images/cloud-front-blue.png);
    background-repeat: no-repeat;
    background-position: center;
    z-index: 3;
  }

  .logo-middle {
    display: block;
    position: absolute;
    left: calc(50% - 50px);
    width: $logo-width;
    height: $logo-height;
    background-image: url(../../../images/logo_bilife.png);
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
  }

  .logo-back {
    display: block;
    position: absolute;
    left: calc(50% - 60px);
    width: $logo-width;
    height: $logo-height;
    background-image: url(../../../images/cloud-back-blue.png);
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }

  .logo-text {
    display: block;
    position: relative;
    text-align: center;
    color: $title-font-color;
    font-size: $title-font-size;
    font-weight: 200;
    padding-top: $logo-height + $logo-text-padding;
    line-height: $title-font-size;
    padding-bottom: $logo-text-padding;
  }
}
