//font
$font-size-title: 0.18rem;
$font-size-name: 0.16rem;
$font-size-content: 0.13rem;

//color
$tips: #e43942;

.policy-new {
  background-color: rgba(248, 248, 248, 1);
  .policy-tips {
    .icon-left {
      float: left;
      margin-top: 0.02rem;
      width: 0.03rem;
      height: 0.18rem;
      background-color: $tips;
      line-height: $font-size-title;
      -webkit-border-radius: 0.1rem;
      -moz-border-radius: 0.1rem;
      border-radius: 0.1rem;
    }
    span {
      font-size: $font-size-title;
      color: rgba(51, 51, 51, 1);
      font-weight: 500;
    }
  }
  .policy-hint,
  .policy-content {
    padding: 0.14rem 0.14rem 0.4rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0rem 0rem 0.1rem 0rem rgba(0, 0, 0, 0.1);
    border-radius: 0.04rem;
    span {
      font-size: $font-size-content;
      line-height: 0.2rem;
      display: block;
      color: rgba(102, 102, 102, 1);
    }
    .title {
      font-size: $font-size-name;
      font-weight: bold;
    }
    .policy-No {
      font-size: 0.12rem;
      color: rgba(102, 102, 102, 1);
    }
  }
  .policy-content {
    span {
      margin-bottom: 0.08rem;
    }
  }
}

.page-body {
  .service-onlineVideo {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 1rem;
    right: 0;
    transform-origin: 0 0;
    width: 0.68rem;
    height: 0.68rem;
    user-select: none;
    outline: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    z-index: 200;
    .service-online-icon {
      width: inherit; //.3rem;
      height: inherit; //.3rem;
      pointer-events: none;
      transform: translateY(-0.07rem);
    }
    .service-online-text {
      display: none;
      background: #c01925;
      border-radius: 1rem;
      color: #ffffff;
      font-size: 0.12rem;
      width: 0.48rem;
      text-align: center;
    }
  }
  .video {
    position: fixed;
    width: 100%;
    top: 0.8rem;
    display: none;
    z-index: 200;
    .video-header {
      position: relative;
      left: 5%;
      width: 90%;
      height: 0.3rem;
      background-color: #000000;
      .icon-close-video {
        position: absolute;
        display: block;
        width: 0.2rem;
        height: 0.2rem;
        right: 0.05rem;
        top: 0.05rem;
        background: url('../images/wechat/clear.png') no-repeat;
        background-size: 100%;
        z-index: 100;
      }
      .icon-close {
        position: absolute;
        display: block;
        width: 0.3rem;
        height: 0.3rem;
        right: 0;
        top: 0.02rem;
        background: url('../images/wechat/clear.png') no-repeat;
        background-size: 100%;
      }
    }
    #dplayer {
      left: 5%;
      width: 90%;
      height: 300px;
    }
  }
  .prompt-wrap {
    padding: 0.15rem;
    background: #edf3fe;
    color: #e43942;
    border-radius: 0.03rem;
    .header-tips {
      font-size: 0.14rem;
      font-weight: bold;
      margin-bottom: 0.04rem;
    }
    .content {
      font-size: 0.12rem;
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
}
